import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import LeftSidebar from "../../Components/LeftSidebar/LeftSidebar";
import RightSidebar from "../../Components/RightSidebar/RightSidebar";
import { MapBox } from "../index";

const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        overflow: "hidden",
        position: "relative",
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    overlay: {
        background: "rgb(0,0,0,0.5)",
        width: "100%",
        height: "100vh",
        position: "absolute",
        top: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    margLeft: {
        left: "80px",
    },
    rightOpen: {
        width: "calc(100vw - 520px)",
    },
}));

const MainContent = () => {
    const [open, setOpen] = useState(true);
    const [rightOpen, setRightOpen] = useState(false);
    const { region } = useParams();

    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden";
        return () => {
            document.querySelector("body").style.overflow = "auto";
        };
    }, []);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleRightDrawerOpen = () => setRightOpen(true);
    const handleRightDrawerClose = () => setRightOpen(false);
    const editorRef = useRef(null);
    const [viewport, setViewport] = useState({
        longitude: 145,
        latitude: -37.78,
        zoom: 12,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onViewportChange = useCallback((data) => setViewport({ ...viewport, ...data }), [viewport.zoom]);

    const isOverlay = useSelector((state) => state.trees.isOverlay);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LeftSidebar
                region={region}
                setViewport={onViewportChange}
                editor={editorRef}
                handleDrawerOpen={handleDrawerOpen}
                handleRightDrawerOpen={handleRightDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                open={open}
            />
            <div
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <MapBox
                    region={region}
                    // viewport={viewport}
                    // setViewport={onViewportChange}
                    editorRef={editorRef}
                    rightOpen={rightOpen}
                    handleRightDrawerOpen={handleRightDrawerOpen}
                />
                {isOverlay && (
                    <div
                        className={clsx(classes.overlay, {
                            [classes.margLeft]: !isOverlay && open,
                            [classes.rightOpen]: rightOpen,
                        })}
                    >
                        <CircularProgress />
                    </div>
                )}
            </div>
            <RightSidebar region={region} handleDrawerOpen={handleRightDrawerOpen} handleDrawerClose={handleRightDrawerClose} open={rightOpen} />
        </div>
    );
};

export default MainContent;
