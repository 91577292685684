import Plot from "react-plotly.js";

const LossGainsChart = (props) => {
    const { data, preview = false, mode, regionArea, polygonArea } = props;

    const modifiedData = (() => {
        if (mode === "polygon_area") {
            return {
                plantings: data.plantings.map((el) => (el / polygonArea) * 100),
                removals: data.removals.map((el) => (el / polygonArea) * 100),
                net: data.net.map((el) => (el / polygonArea) * 100),
            };
        } else if (mode === "total_area") {
            return {
                plantings: data.plantings.map((el) => (el / regionArea) * 100),
                removals: data.removals.map((el) => (el / regionArea) * 100),
                net: data.net.map((el) => (el / regionArea) * 100),
            };
        } else {
            return {
                plantings: data.plantings,
                removals: data.removals,
                net: data.net,
            };
        }
    })();

    const modifiedYAxisTitle = (() => {
        if (mode === "polygon_area") {
            return "% of Polygon Area";
        } else if (mode === "total_area") {
            return "% of Region Area";
        } else {
            return "sqm";
        }
    })();

    const lossGainsData = [
        {
            type: "bar",
            x: data.time_period,
            y: modifiedData.plantings,
            marker: { color: "rgb(80, 123, 88)" },
            name: "plantings",
        },
        {
            type: "bar",
            x: data.time_period,
            y: modifiedData.removals,
            marker: { color: "rgb(179, 49, 49)" },
            name: "removals",
        },
        {
            type: "scatter",
            x: data.time_period,
            y: modifiedData.net,
            marker: {
                size: 6,
                color: "#c4e6c3",
            },
            line: { width: 2 },
            name: "net",
        },
    ];

    const layout = {
        barmode: "relative",
        xaxis: {
            ticks: "outside",
            textposition: "inside",
        },
        yaxis: { title: modifiedYAxisTitle },
        legend: {
            orientation: "h",
            yanchor: "bottom",
            y: 1.02,
            xanchor: "right",
            x: 1,
        },
        width: (preview === true && 560) || 850,
        height: (preview === true && 280) || 650,
    };

    return <Plot data={lossGainsData} layout={layout} config={{ displaylogo: false }} />;
};

export default LossGainsChart;
