import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { FILTERS_RESET, REGION_RESET, SESSIONS_RESET, SIDEBAR_RESET, TREES_RESET } from "./types";

import { composeWithDevTools } from "redux-devtools-extension";
import appReducer from "./AppReducer";
import filterReducer from "./FilterReducer";
import leftSidebarReducer from "./LeftSidebarReducer";
import regionReducer from "./RegionReducer";
import sessionReducer from "./SessionReducer";
import snackbarReducer from "./SnackbarReducer";
import treesReducer from "./TreesReducer";
import userReducer from "./UserReducer";

let reducers = combineReducers({
    app: appReducer,
    user: userReducer,
    trees: treesReducer,
    leftSidebar: leftSidebarReducer,
    sessions: sessionReducer,
    snackbar: snackbarReducer,
    regions: regionReducer,
    filters: filterReducer,
});

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer, composeWithDevTools()];
const composedEnhancers = compose(...enhancers);

const isLocalhost = window.location.hostname === "localhost";
// let store = createStore(reducers, applyMiddleware(thunkMiddleware));
// const store = createStore(reducers, composedEnhancers)
const store = isLocalhost ? createStore(reducers, composedEnhancers) : createStore(reducers, applyMiddleware(thunkMiddleware));

export const ResetToMainPage = () => {
    return (dispatch) => {
        dispatch({ type: REGION_RESET });
        dispatch({ type: SESSIONS_RESET });
        dispatch({ type: FILTERS_RESET });
        dispatch({ type: TREES_RESET });
        dispatch({ type: SIDEBAR_RESET });
    };
};

export default store;
