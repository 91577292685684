/* eslint-disable react-hooks/exhaustive-deps */
import area from "@turf/area";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MapGL, { Layer, NavigationControl, Popup, ScaleControl, Source } from "react-map-gl";
import { EditingMode, Editor } from "react-map-gl-draw";

import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { removeShape, setShape } from "../../Redux/LeftSidebarReducer";
import { getTreeInfoById, setMode, toggleIsOverlay } from "../../Redux/TreesReducer";
import ControlPanel from "./ControlPanel";
import { getEditHandleStyle, getFeatureStyle } from "./styles";

import { Redirect } from "react-router";
import SourceLayer from "./SourceLayer";
import SourcePolygons from "./SourcePolygons";

import { setPolygonsLoaded } from "../../Redux/FilterReducer";
import { setSelectFeatures } from "../../Redux/LeftSidebarReducer";
import { getRegionInfo } from "../../Redux/RegionReducer";
import { getMySessions } from "../../Redux/SessionReducer";
import { ResetToMainPage } from "../../Redux/Store";
import { MAPBOX_TOKEN } from "../../config";
import CustomSource from "./CustomSource";
import { customDatasetStyle, gainsStyle, lossesStyle, polygonsStyle, treeHoverStyle, treeStyle } from "./SourceStyles";
import MixpanelService from "../../services/mixpanel-service";

//import { DrawCircleFromCenterModeLabel } from './MyDraw.js';

// // eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// eslint-disable-next-line import/no-webpack-loader-syntax
// import MapboxWorker, {} from "mapbox-gl";

// import MapboxGlWorker from "mapbox-gl/dist/mapbox-gl-csp-worker";

// mapboxgl.workerClass = MapboxGlWorker;

const navControlStyle = {
    bottom: 50,
    right: 30,
};

const scaleControl = {
    bottom: 50,
    right: 65,
};

const MapBox = ({ region, /*viewport, setViewport,*/ editorRef, rightOpen, handleRightDrawerOpen }) => {
    const dispatch = useDispatch();
    const [isExists, setIsExists] = useState(true);
    // const [mapStyle, setMapStyle] = useState("dark-v10");
    const mapStyle = "dark-v10";
    const [shiftPressed, setShiftPressed] = useState(false);
    const [selectedFeatures, setSelectedFeatures] = useState(null);

    const user = useSelector((state) => state.user.user);

    const mapRef = useRef(null);
    const saveRef = useRef(null);

    const [viewport, setViewport] = useState({
        longitude: 145,
        latitude: -37.78,
        zoom: 12,
    });

    const onViewportChange = useCallback((data) => setViewport({ ...viewport, ...data }), [viewport.zoom]);

    useEffect(() => {
        if (user) {
            MixpanelService.track("open_region", {
                distinct_id: user.id,
                login: user.login,
                role: user.role === 2 ? "admin" : "user",
                region,
            });
        }

        const keyDownHandler = (e) => {
            if (e.keyCode !== 16 || shiftPressed) {
                return;
            }
            setShiftPressed(true);
        };
        const keyUpHandler = (e) => {
            if (e.keyCode !== 16) {
                return;
            }
            setShiftPressed(false);
        };
        window.addEventListener("keyup", keyUpHandler);
        window.addEventListener("keydown", keyDownHandler);

        return () => {
            window.removeEventListener("keydown", keyDownHandler);
            window.removeEventListener("keyup", keyUpHandler);
            dispatch(ResetToMainPage());
            mapboxgl.workerClass = null;
        };
    }, []);

    useEffect(() => {
        dispatch(getRegionInfo(region)).then((regionInfo) => {
            dispatch(getMySessions(regionInfo.id));
            // setViewport({ ...viewport, ...regionInfo.position });
            onViewportChange({ ...viewport, ...regionInfo.position });
            setIsExists(regionInfo);
        });
    }, [dispatch, region]);
    // -37.13734351262877, 145.137451890638886
    const [thereIsLoadEvent, setThereIsLoadEvent] = useState(false);
    const [isGainsLoaded, setIsGainsLoaded] = useState(false);
    const [isLossesLoaded, setIsLossesLoaded] = useState(false);

    const [popupInfo, setPopupInfo] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [modalText, setModalText] = useState("");
    const [hoverInfo, setHoverInfo] = useState(null);
    const [initLosses, setInitLosses] = useState(false);
    const [initGains, setInitGains] = useState(false);
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [circleRadius, setCircleRadius] = useState(null);

    const diapozonHeight = useSelector((state) => state.filters.diapozonHeight);
    const heightFilterZone = useSelector((state) => state.filters.heightFilterZone);
    const yearsDiapozon = useSelector((state) => state.filters.yearsDiapozon);

    const diapozonHeightCustom = useSelector((state) => state.filters.diapozonHeightCustom);
    const heightFilterZoneCustom = useSelector((state) => state.filters.heightFilterZoneCustom);
    const yearsDiapozonCustom = useSelector((state) => state.filters.yearsDiapozonCustom);

    const regionInfo = useSelector((state) => state.regions.regionInfo);
    const mode = useSelector((state) => state.trees.mode);
    const modeInfo = useSelector((state) => state.trees.modeInfo);

    const isFetching = useSelector((state) => state.trees.isFetching);
    const treeInfo = useSelector((state) => state.trees.treeInfo);
    const switches = useSelector((state) => state.trees.switches);
    const hoveredYearLayer = useSelector((state) => state.trees.hoveredYearLayer);

    const customDatasets = useSelector((state) => state.trees.customDatasets);

    useEffect(() => {
        if (switches.showGains) {
            setInitGains(true);
        }
        if (switches.showLosses) {
            setInitLosses(true);
        }
    }, [switches]);

    useEffect(() => {
        const mouseMoveHandler = () => {
            let settings = window.circleRadius;
            if (settings) {
                setCircleRadius(settings);
            }
        };
        if (!saveRef.current) {
            saveRef.current = mouseMoveHandler;
        }

        if (window.isCircle) {
            window.addEventListener("mousemove", saveRef.current);
        } else {
            window.removeEventListener("mousemove", saveRef.current);
        }
    }, [window.isCircle]);

    //Для исключения дерева из общего списка individualTree
    let treeVegId = 0;
    if (treeInfo) {
        treeVegId = treeInfo.veg_id;
    }

    const features = editorRef.current && editorRef.current.getFeatures();
    const selectedFeature = features && features[selectedFeatureIndex];

    const handleEnd = (isSuccess) => {
        if (isSuccess) {
            handleRightDrawerOpen();
        }
        setPopupInfo(null);
        setSelectedFeatureIndex(null);
    };

    const handleModalClick = async (res) => {
        let features = editorRef.current.getFeatures();
        if (res) {
            let duplicate = features.find((f) => f.properties.title === modalText);
            if (duplicate) {
                setModalError(`Shape with title = "${modalText}" already exist`);
                return;
            } else {
                dispatch(setShape(features[features.length - 1]));
                features[features.length - 1].properties.isVisible = true;
                features[features.length - 1].properties.title = modalText;
                if (window.circleRadius && features[features.length - 1].properties.shape === "Circle") {
                    features[features.length - 1].properties.radius = window.circleRadius.toFixed(2);
                }
            }
        } else {
            editorRef.current.deleteFeatures(features.length - 1);
        }
        if (modalError) {
            setModalError(null);
        }

        MixpanelService.track("create_shape", {
            distinct_id: user.id,
            login: user.login,
            role: user.role === 2 ? "admin" : "user",
            region,
            shape: modalText,
            area: area(features[features.length - 1]).toFixed(2),
        });

        setModalText("");
        setModalOpen(false);
    };

    const onSelect = useCallback(
        async (options, e, a) => {
            if (typeof hoverInfo === "string" && !modeInfo) {
                if (
                    (!shiftPressed && !selectedFeatures?.length && selectedFeature === options.selectedFeature) ||
                    (!selectedFeature && !options.selectedFeature)
                ) {
                    MixpanelService.track("individual_tree_request", {
                        distinct_id: user.id,
                        login: user.login,
                        role: user.role === 2 ? "admin" : "user",
                        region,
                        tree_id: hoverInfo,
                    });
                    let result = await dispatch(getTreeInfoById(regionInfo.id, hoverInfo));
                    if (result) {
                        handleRightDrawerOpen();
                    }
                    setHoverInfo(null);
                    return;
                }
            }
            if (shiftPressed) {
                if (!options || options?.selectedFeatureIndex === undefined) {
                    return;
                }
                let mas = selectedFeatures;
                if (selectedFeatureIndex !== null) {
                    mas = [selectedFeatureIndex, options.selectedFeatureIndex];
                    features[selectedFeatureIndex].properties.isSelected = true;
                    features[options.selectedFeatureIndex].properties.isSelected = true;
                    setSelectedFeatureIndex(null);
                    setPopupInfo(null);
                } else {
                    features[options.selectedFeatureIndex].properties.isSelected = true;
                    if (selectedFeatures && !selectedFeatures.includes(options.selectedFeatureIndex)) {
                        mas = [...selectedFeatures, options.selectedFeatureIndex];
                    } else if (!selectedFeatures) {
                        mas = [options.selectedFeatureIndex];
                    }
                }
                setSelectedFeatures(mas);
                dispatch(setSelectFeatures(mas));
            } else {
                if (selectedFeatures && selectedFeatures.length) {
                    selectedFeatures.forEach((ind) => (features[ind].properties.isSelected = false));
                    dispatch(setSelectFeatures([]));
                    setSelectedFeatures(null);
                }
                setSelectedFeatureIndex(options && options.selectedFeatureIndex);
                if (options.selectedFeature && !options.selectedFeature.properties.guideType && options.selectedFeature.geometry.type === "Polygon") {
                    if (features[options.selectedFeatureIndex].properties.isVisible) {
                        let obj = {
                            long: options.selectedFeature.geometry.coordinates[0][0][0],
                            lati: options.selectedFeature.geometry.coordinates[0][0][1],
                        };
                        setPopupInfo(obj);
                    } else if (!popupInfo) {
                        setPopupInfo(null);
                    }
                } else if (!options.selectedFeature) {
                    setPopupInfo(null);
                }
            }
        },
        [hoverInfo, shiftPressed]
    );

    const onDelete = useCallback(() => {
        if (selectedFeatureIndex !== null && selectedFeatureIndex >= 0) {
            editorRef.current.deleteFeatures(selectedFeatureIndex);
            dispatch(removeShape(selectedFeatureIndex));
            setPopupInfo(null);
        }
    }, [dispatch, editorRef, selectedFeatureIndex]);

    const onUpdate = useCallback(
        (inf) => {
            if (inf.editType === "addFeature") {
                window.isCircle = false;
                dispatch(setMode(new EditingMode()));
                setModalOpen(true);
            }
        },
        [dispatch]
    );

    const onClusterClick = async (event) => {
        //event.leftButton
        if (modalOpen || event.target.tagName === "BUTTON" || event.target.tagName === "SPAN") {
            return;
        }
        const feature = event.features[0];
        if (!popupInfo && !shiftPressed && !selectedFeatures?.length && Boolean(feature) && feature.layer.id === "polygons") {
            let result = await dispatch(getTreeInfoById(regionInfo.id, feature.properties.veg_id));
            if (result) {
                handleRightDrawerOpen();
            }
        }
        /*
        if(!feature || !feature.properties.cluster_id) return;

        const clusterId = feature.properties.cluster_id;
    
        const mapboxSource = mapRef.current.getMap().getSource(feature.source);
    
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
                return;
            }
    
            setViewport({
                ...viewport,
                longitude: feature.geometry.coordinates[0],
                latitude: feature.geometry.coordinates[1],
                zoom,
                transitionDuration: 500
            });
        });
        */
    };

    //Создание geojson на хавер при наведении на карту
    const ohHover = useCallback((e) => {
        if (e.features.length) {
            let feature = e.features[0];
            if (feature.layer.id === "polygons") {
                //Для фикса бага когда есть полигон editor можно выбирать деревья только внутри полигона
                setHoverInfo(feature.properties.veg_id);
            } else if (feature.layer.id === "tree_info") {
                feature.properties.hover = true;
                let obj = JSON.parse(JSON.stringify(treeInfo));
                obj.geom.features = [treeInfo.geom.features.find((f) => f.properties.year === feature.properties.year)];
                setHoverInfo({ geojson: obj, x: e.srcEvent.offsetX, y: e.srcEvent.offsetY });
            } else {
                setHoverInfo(null);
            }
        } else {
            setHoverInfo(null);
        }
    });

    //Создание geojson на хавер при наведении на карту или на график
    const treeHover = useMemo(() => {
        if (!treeInfo || typeof hoverInfo === "string" || (!hoverInfo && hoveredYearLayer === -1)) {
            return null;
        }
        if (hoverInfo) {
            return hoverInfo;
        }
        let obj = JSON.parse(JSON.stringify(treeInfo));
        let length = treeInfo.geom.features.length;

        obj.geom.features = [treeInfo.geom.features[length - hoveredYearLayer - 1]];
        return {
            geojson: obj,
        };
    }, [hoverInfo, hoveredYearLayer]);

    if (!isExists) {
        return <Redirect to="/" />;
    }

    /*
    if(!polygons) return (
        <div style={{ display:"flex", justifyContent:"center", margin:"30px", alignItems:"center" }}>
            {
                fetchingProgress && Math.abs(fetchingProgress) != Infinity ?
                <>
                    <CircularProgress style={{ width:"80px", height:"80px" }} variant="determinate" value={fetchingProgress} />
                    <div style={{ position: "absolute"}}>{fetchingProgress}%</div>
                </>
                :
                <CircularProgress />
            }    
        </div>   
    )
    */

    let offset = 0;
    if (rightOpen) {
        offset = 520;
    }

    if (mapRef.current && !thereIsLoadEvent) {
        setThereIsLoadEvent(true);
        mapRef.current.getMap().once("idle", () => {
            dispatch(toggleIsOverlay(false));
            dispatch(setPolygonsLoaded());
        });
    }

    if (initLosses && !isLossesLoaded) {
        setIsLossesLoaded(true);
        dispatch(toggleIsOverlay(true));
        mapRef.current.getMap().once("idle", () => {
            dispatch(toggleIsOverlay(false));
        });
    }

    if (initGains && !isGainsLoaded) {
        setIsGainsLoaded(true);
        dispatch(toggleIsOverlay(true));
        mapRef.current.getMap().once("idle", () => {
            dispatch(toggleIsOverlay(false));
        });
    }

    const getCursor = ({ isHovering, isDragging }) => {
        return isDragging ? "grabbing" : modeInfo ? "crosshair" : isHovering ? "pointer" : "default";
    };

    let tooltip = null;
    if (hoverInfo && hoverInfo.geojson) {
        tooltip = {};
        tooltip.year = hoverInfo.geojson.geom.features[0].properties.year;
        let v = hoverInfo.geojson.graph.find((g) => parseInt(g.year) === tooltip.year).value;
        if (v) {
            tooltip.value = v;
        }
    }

    return (
        <div id="my_map">
            <MapGL
                {...viewport}
                width={`calc(100% - ${offset + "px"})`}
                height="100vh"
                mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                // onViewportChange={setViewport}
                onViewportChange={onViewportChange}
                getCursor={getCursor}
                onClick={onClusterClick}
                onHover={ohHover}
                clickRadius={7}
                interactiveLayerIds={treeInfo ? ["polygons", "tree_info"] : switches.showPolygons ? ["polygons"] : []}
                ref={mapRef}
            >
                {regionInfo && (
                    <>
                        <SourcePolygons
                            id="polygons"
                            hide={!switches.showPolygons}
                            dataUrl={regionInfo.id}
                            styles={polygonsStyle(diapozonHeight, heightFilterZone, treeVegId)}
                        />
                        {initGains && (
                            <SourceLayer
                                id="gains"
                                hide={!switches.showGains}
                                dataUrl={regionInfo.id}
                                styles={gainsStyle(diapozonHeight, heightFilterZone, yearsDiapozon)}
                            />
                        )}
                        {initLosses && (
                            <SourceLayer
                                id="losses"
                                hide={!switches.showLosses}
                                dataUrl={regionInfo.id}
                                styles={lossesStyle(diapozonHeight, heightFilterZone, yearsDiapozon)}
                            />
                        )}
                    </>
                )}

                {Object.keys(customDatasets).map((key) => (
                    <CustomSource
                        key={key}
                        id={key}
                        isFiltered={customDatasets[key].isFiltered}
                        data={customDatasets[key].geom}
                        hide={!customDatasets[key].isShow}
                        styles={customDatasetStyle(
                            key,
                            customDatasets[key].color || ["get", "hex"],
                            diapozonHeightCustom,
                            heightFilterZoneCustom,
                            yearsDiapozonCustom
                        )}
                    />
                ))}

                {treeInfo && (
                    <Source id={"tree_info"} type="geojson" data={treeInfo.geom}>
                        <Layer {...treeStyle} />
                    </Source>
                )}

                {treeHover && treeHover.geojson && treeHover.geojson.geom && (
                    <Source id={"tree_info_hover"} type="geojson" data={treeHover.geojson.geom.features[0]}>
                        <Layer {...treeHoverStyle} />
                    </Source>
                )}

                {modeInfo?.title === "circle" && window.isCircle && (
                    <Popup className="transparent-popup" anchor="top" longitude={window.circleSettings.long} latitude={window.circleSettings.lati}>
                        {window.circleRadius.toFixed(2)} m
                    </Popup>
                )}

                <ScaleControl style={scaleControl} />
                <NavigationControl style={navControlStyle} />
                {mode ? (
                    <Editor
                        ref={editorRef}
                        clickRadius={8}
                        mode={mode}
                        onSelect={onSelect}
                        onUpdate={onUpdate}
                        featuresDraggable={true}
                        editHandleShape={"circle"}
                        featureStyle={getFeatureStyle}
                        editHandleStyle={getEditHandleStyle}
                    />
                ) : (
                    ""
                )}

                {popupInfo && selectedFeature && (
                    <Popup
                        tipSize={5}
                        anchor="right"
                        longitude={popupInfo.long}
                        latitude={popupInfo.lati}
                        closeOnClick={false}
                        onClose={setPopupInfo}
                    >
                        <ControlPanel
                            isFetching={isFetching}
                            shapeTitle={selectedFeatureIndex}
                            polygon={selectedFeature}
                            onHandleDelete={onDelete}
                            onHandleEnd={handleEnd}
                        />
                    </Popup>
                )}

                {tooltip && (
                    <div className="tooltip" style={{ left: hoverInfo.x, top: hoverInfo.y }}>
                        <div>Year: {tooltip.year}</div>
                        <div>
                            Canopy size: {tooltip.value} m<sup>2</sup>
                        </div>
                    </div>
                )}
            </MapGL>

            {/*
                    <div style={helperModeMainBlock}>
                        {
                            modeInfo && 
                            
                            <div style={helperModeStyles(240)}>
                                <span style={{ margin:'0', marginTop: '4px', fontWeight: '600', fontSize: '18px'}}>{ modeInfo.title.toUpperCase() } Builder</span>
                                <p style={{ fontSize: '16px', margin: '10px 15px' }}>{ modeInfo.text }</p>
                                <Button onClick={() => dispatch(setMode(new EditingMode()))} size="small" variant="contained">Cancel</Button>
                            </div>
                            
                        }          
                    </div>
                */}

            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={Boolean(modalOpen)}
                onClose={() => {
                    setModalOpen(false);
                    editorRef.current.deleteFeatures(features.length - 1);
                }}
            >
                <DialogTitle id="form-dialog-title">Layer name</DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="name"
                        type="decription"
                        fullWidth
                        onChange={(e) => setModalText(e.target.value)}
                        onKeyPress={(e) => (e.key === "Enter" && modalText.length ? handleModalClick(true) : null)}
                    />
                    {modalError && <div style={{ color: "red" }}>{modalError}</div>}
                </DialogContent>

                <DialogActions style={{ margin: "5px 15px" }}>
                    <Button onClick={() => handleModalClick(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button disabled={!modalText.length ? true : false} onClick={() => handleModalClick(true)} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MapBox;
