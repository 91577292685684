import ChartLine from "./line";
import ChartPie from "./pie";
import ChartRadar from "./radar";
import { Grid, List, ListItem, ListItemText, IconButton, Card, CardContent, Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ScatterChart from "./scatterGraph";
import LossGainsChart from "./lossGainsChart";
import TotalAreaChart from "./totalAreaChart";
import VegetationRemovalsChart from "./vegetationRemovalsChart";
import TreeHeightsChart from "./treeHeightsChart";
// import CombinedTreeHeightsChart from "./combinedTreeHeightChart";
import WaterfallChart from "./waterfallChart";

import arrowRight from "../../assets/static/arrow-right.svg";
import arrowLeft from "../../assets/static/arrow-left.svg";
import arrowRightDisabled from "../../assets/static/arrow-right-disabled.svg";
import arrowLeftDisabled from "../../assets/static/arrow-left-disabled.svg";
import Info from "../../assets/static/info.svg";
import Send from "../../assets/static/send.svg";
import Loader from "../../assets/static/loader.svg";

import InfoTooltip from "../LeftSidebar/InfoTooltip";

import { useDispatch } from "react-redux";

import React, { useState } from "react";

import { getAiSummary } from "../../Redux/TreesReducer";

const titleStyles = {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
};

const messageStyles = {
    boxShadow: "6px 10px 61px -5px rgba(0,0,0,0.75)",
    borderRadius: "10px",
    width: "45%",
    minHeight: "50px",
    boxSizing: "border-box",
    padding: "10px",
};

const messageWrapperStyles = {
    width: "100%",
    display: "flex",
    position: "relative",
};

const messageInputStyles = {
    width: "100%",
    minWidth: "50%",
    height: "100%",
    border: "none",
    outline: "none",
    background: "white",
    textAlign: "contain",
    boxSizing: "border-box",
};

const GraphTitleHoc = ({ title = "title", children }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={titleStyles}>{title}</div>
            {children}
        </div>
    );
};

const Summary = ({ polygon, graph_type, example_question, summary }) => {
    const dispatch = useDispatch();

    let currentGraphSummaries = [];

    if (summary) {
        currentGraphSummaries = summary.filter((el) => el.graph_type === graph_type);
    }

    const [message, setMessage] = useState({
        text: "",
    });

    const [loading, setLoading] = useState(false);

    const handleGetSummary = async () => {
        setLoading(true);
        const result = await dispatch(getAiSummary(polygon[0].elem, message.text, graph_type));
        setMessage({ text: "" });
        setLoading(false);

        return result;
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                <strong>AI generated data summary</strong>
                <InfoTooltip
                    text={
                        "This is an experimental feature. Please, make sure the prompt you enter describes what you want clearly, otherwise the result will be meaningless."
                    }
                    style={{ position: "absolute", right: "-20px", top: 0 }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "30px",
                    boxSizing: "border-box",
                    padding: "15px",
                }}
            >
                {currentGraphSummaries.length > 0 &&
                    currentGraphSummaries.map((el, id) => {
                        return (
                            <div style={{ width: "100%" }} key={id}>
                                <div style={{ ...messageWrapperStyles, justifyContent: "space-between" }}>
                                    <div style={messageStyles}>
                                        <div
                                            style={{ ...messageInputStyles, boxSizing: "border-box", padding: "5px" }}
                                            placeholder="Enter something to ask AI..."
                                            disabled
                                        >
                                            {el.question}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...messageWrapperStyles, justifyContent: "flex-end" }}>
                                    {/* <input disabled style={{ messageInputStyles }} value={response.text} /> */}
                                    <div style={messageStyles}>
                                        <div style={messageInputStyles} placeholder="AI response..." disabled>
                                            {el.text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <div style={{ ...messageWrapperStyles, justifyContent: "space-between" }}>
                    <div style={messageStyles}>
                        <input
                            style={messageInputStyles}
                            placeholder="Enter something to ask AI..."
                            value={message.text}
                            onChange={(e) => setMessage({ text: e.target.value })}
                        />
                        <InfoTooltip text={`For example enter: ${example_question}`} style={{ position: "absolute", top: "-30px", left: "0" }} />
                    </div>
                    <Button onClick={handleGetSummary} color="primary" endIcon={<img src={Send} with="24" alt="send" />}>
                        Question AI
                    </Button>
                </div>
                <div style={{ ...messageWrapperStyles, justifyContent: "flex-end" }}>
                    {loading === true && <img src={Loader} width="24" alt="loading..." />}
                    <input disabled style={{ messageInputStyles }} placeholder="Response from AI..." />
                </div>
            </div>
        </div>
    );
};

export const pages = [
    {
        title: "Menu",
        key: "menu",
        id: 0,
        description: "",
    },

    {
        title: "Change in Canopy Cover",
        key: "scatter",
        id: 1,
        description: "The graph aggregates canopy cover to the corresponding year.",
    },
    {
        title: "Plantings VS Removals",
        key: "loss_gain",
        id: 2,
        description: `Recorded planting and removal event are aggregated to the corresponding period which it occurred.<br/>The graph is intended to show the overall trend in the number of trees planted vs removed over time. It helps to understand how planting and removal events have affected the size of the urban forest present and future. <br/>A planting is detected when a tree has approximately 1 square meter of tree foliage, and will contribute to the future urban forest.`,
    },
    {
        title: "Canopy Gained due to Plantings",
        key: "total_area",
        id: 3,
        description:
            "The graphs show proportionally the total canopy gained due to plantings.<br/>The information is separated into land use categories.<br/>The graph is an indicator of the amount of change occurring in the urban forest over the study period.",
    },
    {
        title: "Canopy Lost due to Removals",
        key: "vegetation_removals",
        id: 4,
        description:
            "The graphs show proportionally the total canopy lost due to removals.<br/>The information is separated into land use categories, and is an indicator of the amount of change occurring in the urban forest over the study period.",
    },
    {
        title: "Canopy of Region by Height",
        key: "tree_height",
        id: 5,
        description: "The maximum height of a tree is used to aggregated canopy to a range of height intervals. ",
    },
    {
        title: "Drivers of Change",
        key: "waterfall",
        id: 6,
        description:
            "The following graph shows the cumulative effect of plantings, removals and changes in existing foliage.<br/>By tracking trees that will eventually be removed and the growth of trees that are planted it is possible to identify the drivers of change across each time period.<br/>Rises and decline are represented in the foliage Growth and Loss categories and can be indicators of canopy change due to climate, pruning or senescing. ",
    },
    {
        title: "Land Use Type",
        key: "pie",
        id: 7,
        description: "A pie chart that shows distribution of canopy by landuse.",
    },
    // {
    //     title: "Net Loss v Gain Bar Chart",
    //     key: "bar",
    //     id: 2,
    //     description:
    //         "A line chart where first line shows all observed vegetation that includes losses and gains and second line shows vegetation that excludes losses and gains.",
    // },
    {
        title: "Canopy Cover",
        key: "chart",
        id: 8,
        description:
            "A line chart where first line shows all observed vegetation that includes losses and gains and second line shows vegetation that excludes losses and gains.",
    },
];

export const Graph = (props) => {
    const {
        dataChart,
        dataPie,
        dataBar,
        mode,
        polygonArea,
        regionArea,
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataTreeHeights,
        dataTreeHeightsAboveGround,
        dataVegetationRemovals,
        dataWaterfall,
        lastYear,
        startingCanopy,
        status,
        contribution,
        currentCanopy,
        polygon,
        summary,
        changeRatio,
        firstYear,
    } = props;

    const data = {
        chart: dataChart,
        bar: dataBar,
        pie: dataPie,
        scatter: dataScatter,
        loss_gain: dataLossGains,
        total_area: dataTotalArea,
        tree_height: dataTreeHeights,
        vegetation_removals: dataVegetationRemovals,
        tree_heights_above_ground: dataTreeHeightsAboveGround,
        waterfall: dataWaterfall,
        last_year: lastYear,
        starting_canopy: startingCanopy,
        status: status,
        contribution: contribution,
        current_canopy: currentCanopy,
        change_ratio: changeRatio,
        first_year: firstYear,
    };

    const [currentPage, setCurrentPage] = useState({
        title: "Menu",
        key: "menu",
        id: 0,
    });

    const handleNextGraph = () => {
        if (currentPage.id === Object.keys(pages).length - 1) {
            return;
        }
        setCurrentPage((prev) => pages[prev.id + 1]);
    };

    const handlePreviousGraph = () => {
        if (currentPage.id === 0) {
            return;
        }
        setCurrentPage((prev) => pages[prev.id - 1]);
    };

    const [modalOpen, setModalOpen] = useState({
        id: -1,
        status: false,
    });

    // if (mode === "polygon_area") {
    //     data = {
    //         ...data,
    //         bar: dataBar.map((el) => {
    //             return {
    //                 ...el,
    //                 Gain: (el.Gain / polygonArea) * 100,
    //                 Loss: (el.Loss / polygonArea) * 100,
    //                 Net: (el.Net / polygonArea) * 100,
    //             };
    //         }),
    //         chart: dataChart.map((el) => {
    //             return {
    //                 ...el,
    //                 existing: (el.existing / polygonArea) * 100,
    //                 total: (el.total / polygonArea) * 100,
    //             };
    //         }),
    //     };
    // }

    // if (mode === "total_area") {
    //     data = {
    //         ...data,
    //         bar: dataBar.map((el) => {
    //             return {
    //                 ...el,
    //                 Gain: (el.Gain / regionArea) * 100,
    //                 Loss: (el.Loss / regionArea) * 100,
    //                 Net: (el.Net / regionArea) * 100,
    //             };
    //         }),
    //         chart: dataChart.map((el) => {
    //             return {
    //                 ...el,
    //                 existing: (el.existing / regionArea) * 100,
    //                 total: (el.total / regionArea) * 100,
    //             };
    //         }),
    //     };
    // }

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} className="total_area_graph" style={{ marginTop: "30px", width: "100%" }}>
                <div
                    className="graph-slider-handlers"
                    style={{
                        display: "flex",
                        gap: "30px",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                    }}
                >
                    <div onClick={handlePreviousGraph} style={{ cursor: currentPage.id === 0 ? "" : "pointer" }}>
                        <img src={currentPage.id === 0 ? arrowLeftDisabled : arrowLeft} alt="arrow-left" width="48" />
                    </div>
                    <div onClick={handleNextGraph} style={{ cursor: currentPage.id === Object.keys(pages).length - 1 ? "" : "pointer" }}>
                        <img src={currentPage.id === Object.keys(pages).length - 1 ? arrowRightDisabled : arrowRight} alt="arrow-right" width="48" />
                    </div>
                </div>

                {currentPage.key === "menu" && (
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: "60px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap", justifyContent: "space-around" }}>
                            <Card style={{ width: "300px", minHeight: "200px" }}>
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                        gap: "30px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <p style={{ fontSize: "24px" }}>
                                        <strong>{data.current_canopy.toFixed(2)}%</strong>
                                    </p>
                                    <p style={{ textAlign: "center" }}>Is the canopy cover in year {data.last_year}</p>
                                </CardContent>
                            </Card>
                            <Card style={{ width: "300px", minHeight: "200px" }}>
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                        gap: "30px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <p style={{ fontSize: "24px" }}>
                                        <strong>{data.contribution.toFixed(2)}%</strong>
                                    </p>
                                    <p style={{ textAlign: "center" }}>Is current polygon's contribution to the total case study canopy cover</p>
                                </CardContent>
                            </Card>
                            <Card style={{ width: "300px", minHeight: "200px", marginTop: "30px" }}>
                                <CardContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        height: "100%",
                                        gap: "30px",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <p style={{ fontSize: "24px" }}>
                                        <strong>{data.change_ratio.toFixed(2)}%</strong>
                                    </p>
                                    <p style={{ textAlign: "center" }}>
                                        Is a {data.status} of canopy cover to what was measured in {data.first_year}
                                    </p>
                                </CardContent>
                            </Card>
                        </div>
                        <h1 style={{ justifyContent: "center", marginTop: "30px" }}>
                            <strong>Menu</strong>
                        </h1>
                        <List style={{ display: "flex", flexWrap: "wrap", marginTop: "30px" }}>
                            {pages.map((el) => {
                                return el.key !== "menu" ? (
                                    <ListItem
                                        key={el.key}
                                        onClick={(e) => {
                                            if (e.target.localName === "img") {
                                                return;
                                            }
                                            setCurrentPage({
                                                title: el.title,
                                                key: el.key,
                                                id: el.id,
                                            });
                                        }}
                                        dense
                                        button
                                        style={{ flex: "0 0 50%" }}
                                    >
                                        <ListItemText>{el.title}</ListItemText>
                                        <IconButton
                                            edge="end"
                                            onClick={() =>
                                                setModalOpen({
                                                    status: true,
                                                    id: el.id,
                                                })
                                            }
                                        >
                                            <img src={Info} with="24" alt="info" />
                                        </IconButton>
                                    </ListItem>
                                ) : null;
                            })}
                        </List>
                    </div>
                )}
                {currentPage.key === "pie" && dataPie && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ChartPie data={data.pie} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "bar" && dataBar && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ChartRadar data={data.bar} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "chart" && dataChart && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ChartLine
                            data={data.chart}
                            area
                            withHelp
                            lines={[
                                {
                                    key: "total",
                                    label: "Total vegetation",
                                    stroke: "#72D09C",
                                    help: "All observed vegetation including losses and gains.",
                                },
                                {
                                    key: "existing",
                                    label: "Baseline vegetation",
                                    stroke: "#68A673",
                                    help: "Vegetation that is consistently observed throughout the study period. Excludes losses and gains.",
                                },
                            ]}
                            mode={mode}
                            polygonArea={polygonArea}
                            regionArea={regionArea}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "scatter" && dataScatter && (
                    <GraphTitleHoc title={currentPage.title}>
                        <ScatterChart data={data.scatter} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="scatter"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "loss_gain" && dataLossGains && (
                    <GraphTitleHoc title={currentPage.title}>
                        <LossGainsChart data={data.loss_gain} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="loss_gain"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "total_area" && dataTotalArea && (
                    <GraphTitleHoc title="Canopy Gained due to Plantings">
                        <TotalAreaChart data={data.total_area} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="total_area"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "vegetation_removals" && dataVegetationRemovals && (
                    <GraphTitleHoc title={currentPage.title}>
                        <VegetationRemovalsChart data={data.vegetation_removals} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="vegetation_removals"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "tree_height" && dataTreeHeights && (
                    <GraphTitleHoc title={currentPage.title}>
                        <TreeHeightsChart data={data.tree_height} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="tree_height"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
                {currentPage.key === "waterfall" && dataWaterfall && (
                    <GraphTitleHoc title={currentPage.title}>
                        <WaterfallChart data={data.waterfall} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        <Summary
                            example_question="How do plantings and removals effect canopy cover through time ?"
                            polygon={polygon}
                            graph_type="waterfall"
                            summary={summary}
                        />
                    </GraphTitleHoc>
                )}
            </Grid>
            <Dialog
                open={modalOpen.status}
                onClose={() =>
                    setModalOpen({
                        id: -1,
                        status: false,
                    })
                }
            >
                {modalOpen.id !== -1 && (
                    <>
                        <DialogTitle>{pages[modalOpen.id].title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {pages[modalOpen.id].description.split("<br/>").map((str) => (
                                    <p key={() => Math.random()}>{str}</p>
                                ))}
                            </DialogContentText>
                            <DialogContentText>For more detailed information navigate to page with the graph</DialogContentText>
                            <div>See the preview of the graph below:</div>

                            {pages[modalOpen.id].key === "pie" && dataPie && (
                                <ChartPie data={data.pie} mode={mode} preview={true} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "bar" && dataBar && (
                                <ChartRadar data={data.bar} mode={mode} preview={true} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "chart" && dataChart && (
                                <ChartLine
                                    data={data.chart}
                                    area
                                    withHelp
                                    lines={[
                                        {
                                            key: "total",
                                            label: "Total vegetation",
                                            stroke: "#72D09C",
                                            help: "All observed vegetation including losses and gains.",
                                        },
                                        {
                                            key: "existing",
                                            label: "Baseline vegetation",
                                            stroke: "#68A673",
                                            help: "Vegetation that is consistently observed throughout the study period. Excludes losses and gains.",
                                        },
                                    ]}
                                    mode={mode}
                                    preview={true}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {pages[modalOpen.id].key === "scatter" && dataScatter && (
                                <ScatterChart data={data.scatter} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "loss_gain" && dataLossGains && (
                                <LossGainsChart data={data.loss_gain} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "total_area" && dataTotalArea && (
                                <TotalAreaChart data={data.total_area} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )}
                            {pages[modalOpen.id].key === "vegetation_removals" && dataVegetationRemovals && (
                                <VegetationRemovalsChart
                                    data={data.vegetation_removals}
                                    preview={true}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {pages[modalOpen.id].key === "tree_height" && dataTreeHeights && (
                                <TreeHeightsChart
                                    data={data.tree_height}
                                    preview={true}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            )}
                            {pages[modalOpen.id].key === "waterfall" && dataWaterfall && (
                                <WaterfallChart data={data.waterfall} preview={true} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                            )}

                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        const graphInfo = {
                                            title: pages[modalOpen.id].title,
                                            key: pages[modalOpen.id].key,
                                            id: modalOpen.id,
                                        };
                                        setModalOpen({
                                            id: -1,
                                            status: false,
                                        });
                                        setCurrentPage(graphInfo);
                                    }}
                                    color="primary"
                                >
                                    open graph
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </Grid>
    );
};
