import area from "@turf/area";
import bbox from "@turf/bbox";
import centroid from "@turf/centroid";
import { featureCollection } from "@turf/helpers";
import React, { useEffect } from "react";
import { Layer, Source, StaticMap, WebMercatorViewport } from "react-map-gl";
import { MAPBOX_TOKEN } from "../../config";
import ChartLineForPDF from "../GraphComponents/chartLineForPDF";
import ChartPie from "../GraphComponents/pie";

import ScatterChart from "../GraphComponents/scatterGraph";
import LossGainsChart from "../GraphComponents/lossGainsChart";
import TotalAreaChart from "../GraphComponents/totalAreaChart";
// import vegetationRemovalsChart from "../GraphComponents/vegetationRemovalsChart";
import TreeHeightsChart from "../GraphComponents/treeHeightsChart";
import WaterfallChart from "../GraphComponents/waterfallChart";
import VegetationRemovalsChart from "../GraphComponents/vegetationRemovalsChart";

const treeStyle = (id) => ({
    id: `poly`,
    type: "line",
    paint: {
        "line-color": "#FFC414",
        "line-width": 2.5,
    },
});

const PDFComponent = React.forwardRef((props, ref) => {
    const { years, agregateGraph, region, filters, chartsForPDF, mode, polygonArea, regionArea } = props;

    useEffect(() => {
        document.querySelector(`#print_butt`).setAttribute("disabled", "disabled");
    }, []);

    if (!chartsForPDF) {
        let but = document.querySelector(`#print_butt`);
        if (but && !but.hasAttribute("disabled")) {
            but.setAttribute("disabled", "disabled");
        }
        return null;
    }

    let features = [];
    chartsForPDF.forEach((el) => {
        features.push(el.elem);
    });

    let collection = featureCollection(features);
    let boundBox = bbox(collection);

    let cornersLongLat = [
        [boundBox[0], boundBox[1]],
        [boundBox[2], boundBox[3]],
    ];

    let viewport = new WebMercatorViewport({ width: 400, height: 300 }).fitBounds(cornersLongLat, { padding: 40 });
    let { longitude, latitude, zoom } = viewport;
    //pdf_print_block нужен для показа компонента только на генерации PDF

    return (
        <div className="pdf_print_block" style={{ width: "80%", margin: "60px auto" }} ref={ref}>
            <div>
                <div>
                    <h3>CANOPY REPORT</h3>
                    <p>
                        Data Extracted {new Date().toDateString()} from <b>{region}</b> using LEAF
                    </p>
                    <p>
                        <b>Name:</b> {chartsForPDF.map((c) => c.title).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Location:</b> {chartsForPDF.map((c) => `[${centroid(c.elem).geometry.coordinates}]`).join(", ")}{" "}
                    </p>
                    <p>
                        <b>Area:</b>{" "}
                        {chartsForPDF.map((c, ind) => (
                            <span key={ind} style={{ marginLeft: 5 }}>
                                {Math.round(area(c.elem))} m<sup>2</sup>
                            </span>
                        ))}{" "}
                    </p>

                    <div style={{ display: "flex", gap: "10px" }}>
                        <div>
                            <b>Filters:</b>
                        </div>
                        <div>
                            <div>
                                Heights:{" "}
                                {typeof filters.height === "string"
                                    ? filters.height
                                    : `${filters.height[0] === -1 ? 0 : filters.height[0]} - ${filters.height[1]} m`}
                            </div>
                            <div>Years: {years}</div>
                            <div>Land use: {filters.zones.join(", ")}</div>
                        </div>
                    </div>

                    <hr></hr>
                </div>

                <div>
                    <h3>Map</h3>
                    <div style={{ width: "534px", height: "400px" }}>
                        <img id={"poly_img"} src={null} alt={"map"} style={{ width: "100%", height: "100%" }} />
                        <StaticMap
                            width="100%"
                            height="100%"
                            visible={false}
                            attributionControl={false}
                            preserveDrawingBuffer={true}
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            latitude={latitude}
                            longitude={longitude}
                            zoom={zoom}
                            onLoad={(e) => {
                                document.querySelector(`#print_butt`).removeAttribute("disabled");
                                document.querySelector(`#poly_img`).src = e.target.getCanvas().toDataURL();
                            }}
                        >
                            {collection && (
                                <Source id={"poly"} type="geojson" data={collection}>
                                    <Layer {...treeStyle(1)} />
                                </Source>
                            )}
                        </StaticMap>
                    </div>
                </div>

                <div className="page-break" />

                {agregateGraph && (
                    <div>
                        {/* <div className="page-break" />

<h3 style={{ marginTop: "80px" }}>Net Loss v Gain</h3>
                        <div
                        style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                            >
                            <div>Net Loss v Gain</div>
                            <NetGraph data={agregateGraph.dataBar} mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        </div> */}

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Change in Canopy Cover</div>
                            <ScatterChart
                                data={agregateGraph.dataScatter}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Plantings VS Removals</div>
                            <LossGainsChart
                                data={agregateGraph.dataLossGains}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Canopy Gained due to Plantings</div>
                            <TotalAreaChart
                                data={agregateGraph.dataTotalArea}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Canopy Lost due to Removals</div>
                            <VegetationRemovalsChart
                                data={agregateGraph.dataVegetationRemovals}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Canopy of Region by Height</div>
                            <TreeHeightsChart
                                data={agregateGraph.dataTreeHeights}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                        </div>

                        <div className="page-break" />

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Drivers of Change</div>
                            <WaterfallChart
                                data={agregateGraph.dataWaterfall}
                                forPdf={true}
                                mode={mode}
                                polygonArea={polygonArea}
                                regionArea={regionArea}
                            />
                        </div>

                        <div className="page-break" />

                        {/* <h3 style={{ marginTop: "80px" }}>DATA INSIGHTS</h3> */}

                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                padding: "20px 0 70px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Canopy by Land Use Types</div>
                            <ChartPie data={props.agregateGraph.dataPie} large mode={mode} polygonArea={polygonArea} regionArea={regionArea} />
                        </div>

                        <div className="page-break" />

                        <h3 style={{ marginTop: "80px" }}>Total Urban Forest Cover</h3>
                        <div
                            style={{
                                width: "100%",
                                marginTop: "40px",
                                paddingTop: "20px",
                                color: "#585858",
                                fontSize: "24px",
                                fontWeight: "500",
                                // border: "1px solid grey",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <div>Total Urban Forest Cover</div>

                            {
                                <ChartLineForPDF
                                    data={agregateGraph.dataLine}
                                    area
                                    lines={[
                                        {
                                            key: "total",
                                            label: "Total vegetation",
                                            stroke: "#72D09C",
                                            help: "All observed vegetation including losses and gains.",
                                        },
                                        {
                                            key: "existing",
                                            label: "Baseline vegetation",
                                            stroke: "#68A673",
                                            help: "Vegetation that is consistently observed throughout the study period. Excludes losses and gains.",
                                        },
                                    ]}
                                    mode={mode}
                                    polygonArea={polygonArea}
                                    regionArea={regionArea}
                                />
                            }
                        </div>
                    </div>
                )}

                <div className="page-footer">
                    Commercial-in-confidence | Player Piano Data Analytics | E: lab@ppdataanalytics.com | W: https://ppdataanalytics.com
                </div>
            </div>
        </div>
    );
});

export default PDFComponent;
