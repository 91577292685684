import { usersAPI } from "./API/Api";
import { showSnackbar } from "./SnackbarReducer";
import { ADD_REGION_TO_USER, REMOVE_REGION_FROM_USER, SET_IS_AUTH, SET_USERS_DATA, SET_USER_DATA, TOGGLE_IS_FETCHING } from "./types";
import MixpanelService from "../services/mixpanel-service";

let initialState = {
    countUsers: 0,
    users: [],
    user: null,
    isAuth: false,
    isFetching: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return { ...state, user: action.user, isAuth: action.isAuth };
        case TOGGLE_IS_FETCHING:
            return { ...state, isFetching: action.isFetching };
        case SET_IS_AUTH:
            return { ...state, isAuth: action.isAuth, user: action.isAuth ? state.user : null };
        case SET_USERS_DATA:
            return { ...state, users: action.users, countUsers: action.countUsers };
        case ADD_REGION_TO_USER: {
            let users = state.users;
            let current = state.users.find((u) => u.id === action.userId);
            current.access_regions.push(action.regionId);
            return { ...state, users };
        }
        case REMOVE_REGION_FROM_USER: {
            let users = state.users;
            let current = state.users.find((u) => u.id === action.userId);
            current.access_regions = current.access_regions.filter((r) => r !== action.regionId);
            return { ...state, users };
        }
        default:
            return state;
    }
};

export const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });
export const setAuthUserData = (user, isAuth) => ({ type: SET_USER_DATA, user, isAuth });
export const setAuth = (isAuth) => ({ type: SET_IS_AUTH, isAuth });
export const setUsersData = (users, countUsers) => ({ type: SET_USERS_DATA, users, countUsers });
export const addRegionTo = (userId, regionId) => ({ type: ADD_REGION_TO_USER, userId, regionId });
export const removeRegion = (userId, regionId) => ({ type: REMOVE_REGION_FROM_USER, userId, regionId });

export const getUsers =
    (page = 1) =>
    (dispatch) => {
        usersAPI.getUsersApi(page).then((response) => {
            if (response.data.status === true) {
                dispatch(setUsersData(response.data.data, response.data.count));
            }
        });
    };

export const getAuthUserData = () => async (dispatch) => {
    // await usersAPI.getUser().then((response) => {
    //     dispatch(setAuthUserData(response.data.data, true));
    // });

    const response = await usersAPI.getUser();
    if (response.data.status === true) {
        MixpanelService.track("session_start", {
            user_id: response.data.data.id,
            login: response.data.data.login,
            role: response.data.data.role === 2 ? "admin" : "user",
            session_start_date: new Date().toUTCString(),
        });
    }
    if (response) {
        dispatch(setAuthUserData(response.data.data, true));
    }
};

export const login = (login, password) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    // await usersAPI
    //     .login(login, password)
    //     .then((response) => {
    //         if (!response.data.status === false) {
    //             dispatch(toggleIsFetching(false));
    //             localStorage.setItem("auth_token", response.data.data.token);
    //             dispatch(getAuthUserData());
    //             //dispatch(showSnackbar("Success login", "success"))
    //         }
    //     })
    //     .catch(() => {
    //         dispatch(toggleIsFetching(false));
    //         dispatch(showSnackbar("Incorrect login or password", "error"));
    //     });
    try {
        const response = await usersAPI.login(login, password);
        if (!response.data.status === false) {
            dispatch(toggleIsFetching(false));
            localStorage.setItem("auth_token", response.data.data.token);
            dispatch(getAuthUserData());
            MixpanelService.track("login_with_login_and_password", {
                distinct_id: response.data.data.id,
                user_id: response.data.data.id,
                login: response.data.data.login,
                role: response.data.data.role === 2 ? "admin" : "user",
                login_date: new Date().toUTCString(),
            });
        }
    } catch (error) {
        dispatch(toggleIsFetching(false));
        dispatch(showSnackbar("Incorrect login or password", "error"));
    }
};

export const logout = () => {
    return async (dispatch) => {
        localStorage.removeItem("auth_token");
        dispatch(setAuth(false));
        dispatch(showSnackbar("Success logout", "success"));
    };
};

export const register = (login, password) => (dispatch) => {
    dispatch(toggleIsFetching(true));
    usersAPI
        .register(login, password)
        .then((response) => {
            if (!response.data.status === false) {
                dispatch(toggleIsFetching(false));
                dispatch(getUsers());
                dispatch(showSnackbar("User created successfully", "success"));
            }
        })
        .catch(() => {
            dispatch(toggleIsFetching(false));
            dispatch(showSnackbar("This login already exists", "error"));
        });
};

export const addRegionToUser = (userId, regionId) => async (dispatch) => {
    try {
        await usersAPI.addRegionToUser(userId, regionId);
        dispatch(addRegionTo(userId, regionId));
        dispatch(showSnackbar("Region was added successfully", "success"));
    } catch (e) {
        dispatch(showSnackbar(e.message, "error"));
    }
};

export const removeRegionFromUser = (userId, regionId) => async (dispatch) => {
    try {
        await usersAPI.removeRegionFromUser(userId, regionId);
        dispatch(removeRegion(userId, regionId));
        dispatch(showSnackbar("Region was removed successfully", "success"));
    } catch (e) {
        dispatch(showSnackbar(e.message, "error"));
    }
};

export const updateUser = (userId, payload) => async (dispatch) => {
    try {
        await usersAPI.updateUser(userId, payload);
        dispatch(getUsers());
        dispatch(showSnackbar("Role was updated successfully", "success"));
    } catch (e) {
        dispatch(showSnackbar(e.message, "error"));
    }
};

export const updatePassword = (userId, password) => async (dispatch) => {
    try {
        await usersAPI.updateUserPassword(userId, password);
        dispatch(showSnackbar("Password was updated successfully", "success"));
    } catch (e) {
        dispatch(showSnackbar(e.message, "error"));
    }
};

export const deleteUser = (id) => (dispatch) => {
    usersAPI.deleteUserApi(id).then((response) => {
        if (!response.data.status === false) {
            dispatch(getUsers());
            dispatch(showSnackbar("User was deleted", "success"));
        }
    });
};

export default userReducer;
