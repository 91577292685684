import React from "react";
import { useDispatch } from "react-redux";
import { setHoveredYearLayer } from "../../Redux/TreesReducer";
import ChartLine from "./line";

const spanStyle = {
    position: "absolute",
    fontSize: "16px",
    marginTop: "-10px",
    color: "#888888",
    fontWeight: "bold",
    textTransform: "uppercase",
};

const container = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
};

const liStyle = {
    fontSize: "14px",
    margin: "10px 0",
};

export const IndividualGraph = ({ graph, type, geom, landuse, veg_id }) => {
    const dispatch = useDispatch();

    const handleSetActive = (year) => {
        let index = -1;
        graph.forEach((el, ind) => (year === el.year ? (index = ind) : null));
        dispatch(setHoveredYearLayer(index));
    };

    return (
        <div style={container}>
            Tree ID: {veg_id}
            <div style={{ position: "relative", marginTop: "84px" }}>
                <span style={spanStyle}>Growth</span>
                <ChartLine
                    data={graph}
                    name={"year"}
                    lines={[{ key: "value", label: "Canopy size", stroke: "#68A673" }]}
                    handleSetActive={handleSetActive}
                    individual={true}
                />
            </div>
            <div style={{ width: "100%", marginTop: "30px" }}>
                <h4 style={spanStyle}>METADATA</h4>
                <ul style={{ marginTop: "20px" }}>
                    <li style={liStyle}>Tree ID: {veg_id}</li>
                    <li style={liStyle}>Classification: {type}</li>
                    <li style={liStyle}>Latitude: {geom.features[0].geometry.coordinates[0][0][0][0]}</li>
                    <li style={liStyle}>Longtitude: {geom.features[0].geometry.coordinates[0][0][0][1]}</li>
                    <li style={liStyle}>Landuse: {landuse}</li>
                </ul>
            </div>
        </div>
    );
};
