import { AppBar, Button, Fade, Menu, MenuItem, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../Redux/UserReducer";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(3),
    },
    title: {
        color: "white",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
    },
    title_head: {
        color: "white",
        fontSize: "30px",
        margin: 0,
        paddingRight: "20px",
    },
    link: {
        color: "white",
    },
    appbar: {
        minHeight: "72px",
        flexWrap: "wrap",
    },
}));

const NavBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                style={{
                    backgroundColor: "#383838",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Toolbar classes={{ root: classes.appbar }}>
                    <div className={classes.title}>
                        <NavLink to="/" style={{ textDecoration: "none" }}>
                            <h4 className={classes.title_head}>LEAF</h4>
                        </NavLink>
                    </div>
                </Toolbar>
                <div>
                    {user && (
                        <>
                            <Button
                                aria-controls="fade-menu"
                                aria-haspopup="true"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                className={classes.link}
                            >
                                {user.login}
                                {open ? <IconExpandLess /> : <IconExpandMore />}
                            </Button>

                            <Menu
                                elevation={0}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                TransitionComponent={Fade}
                            >
                                {user.role === 2 ? (
                                    <MenuItem component={Link} to={`/profile`} onClick={() => setAnchorEl(null)}>
                                        Profile
                                    </MenuItem>
                                ) : null}
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        dispatch(logout());
                                    }}
                                >
                                    Sign out
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </div>
            </AppBar>
        </div>
    );
};

export default NavBar;
