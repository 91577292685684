import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Slider } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDiapozonHeight, setHeightFilterZone, setYearsDiapozon } from "../../Redux/FilterReducer";
import Switch from "@material-ui/core/Switch";
import { setLayerVisible, setYears } from "../../Redux/TreesReducer";
import InfoTooltip from "./InfoTooltip";
import MixpanelService from "../../services/mixpanel-service";

const useStyles = makeStyles(() => ({
    main: {
        width: "260px",
        padding: "15px 20px",
    },
    filter: {
        position: "relative",
    },
    filter__head: {
        fontSize: 16,
        paddingBottom: "10px",
        fontWeight: "500",
        display: "flex",
        "& span": {
            minWidth: 60,
        },
    },
    filter__divider: {
        margin: "8px 0 15px",
        borderBottom: "1px solid #BBBBBB",
    },
    land__item: {
        display: "flex",
        alignItems: "center",
        "& span": {
            fontSize: "12px",
        },
    },
    land__checkbox: {
        "& svg": {
            fontSize: "19px",
            padding: 0,
        },
        "& span": {
            padding: 0,
        },
    },
    timeline_block: {
        display: "flex",
        justifyContent: "center",
    },
    timeline: {
        width: "90%",
        color: "#505050",
        "& span": {
            fontSize: "10px",
        },
    },
    timeline_label_text: {
        width: "90%",
        color: "#505050",
        "& span": {
            fontSize: "12px",
        },
    },
    height_min: {
        position: "absolute",
        left: "0",
        bottom: "0px",
        fontSize: "13px",
    },
    height_max: {
        position: "absolute",
        right: "0",
        bottom: "0px",
        fontSize: "13px",
    },
    arrow_back: {
        position: "absolute",
        right: "15px",
        top: "5px",
        zIndex: "10",
    },
    zones: {
        display: "flex",
        flexWrap: "wrap",
    },
    appBar: {
        width: "100%",
        height: "30px",
    },
    rootTab: {
        minWidth: "50%",
        padding: 0,
        minHeight: "30px",
    },
    rootTabs: {
        minHeight: "auto",
    },
}));

const SwitchCreator = (color) =>
    withStyles({
        switchBase: {
            top: 3,
            color: "white",
            "&$checked": {
                color: color,
            },
            "&$checked + $track": {
                backgroundColor: color,
            },
        },
        checked: {},
        track: {
            marginTop: 3,
            backgroundColor: "grey",
        },
    })(Switch);

const CurrentSwitch = SwitchCreator("#68A673");
const GainsSwitch = SwitchCreator("#A2D8B9");
const LossesSwitch = SwitchCreator("#FC4236");

const LANDUSE_HELP = "Use tick boxes to filter by land use type. Classifications as supplied by Council. ";
const LENSES_HELP = `“Tree canopy” defined as 
vegetation with a max height 
of over 2 metres. Gains are 
new plantings observed during 
the study period. Losses are 
complete removals observed 
during the study period.`;
const HEIGHT_HELP = `Use sliders to define heights of 
interest. Max height extracted 
from the latest supplied aerial 
LiDAR survey`;
const YEAR_HELP = `Use sliders to define years of interest`;

const FiltersBasic = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [landusesCheckbox, setLandusesCheckbox] = useState({});
    const [selectedHeight, setSelectedHeight] = useState([0, 1]);
    const [selectedYear, setSelectedYear] = useState([0, 0]);

    //для useEffect чтобы установить текущие значения
    const heightFilterZone = useSelector((state) => state.filters.heightFilterZone);
    const diapozonHeight = useSelector((state) => state.filters.diapozonHeight);
    const yearsDiapozon = useSelector((state) => state.filters.yearsDiapozon);

    const user = useSelector((state) => state.user.user);

    const landusesStore = useSelector((state) => state.filters.landuses);
    const switches = useSelector((state) => state.trees.switches);
    const maxHeight = useSelector((state) => state.trees.maxHeight);
    const years = useSelector((state) => state.trees.years);
    const regionInfo = useSelector((state) => state.regions.regionInfo);

    useEffect(() => {
        let mas = {};
        landusesStore.forEach((key) => (mas[key] = false));
        heightFilterZone.forEach((zone) => (mas[zone] = true));

        let min = diapozonHeight[0][2];
        if (min === -1) {
            min = 0;
        }

        setSelectedHeight([min, diapozonHeight[1][2]]);
        if (yearsDiapozon.length) {
            let y0 = years.find((y) => y.label === yearsDiapozon[0]).value;
            let y1 = years.find((y) => y.label === yearsDiapozon[yearsDiapozon.length - 1]).value;
            setSelectedYear([y0, y1]);
        }
        setLandusesCheckbox(mas);
    }, [diapozonHeight, heightFilterZone, landusesStore, years, yearsDiapozon]);

    useEffect(() => {
        if (!yearsDiapozon.length && years.length) {
            setSelectedYear([0, years.length - 1]);
        }
    }, [years, yearsDiapozon.length]);

    useEffect(() => {
        if (diapozonHeight[1][2] === 100) {
            setSelectedHeight([selectedHeight[0], Math.ceil(maxHeight)]);
        }
    }, [diapozonHeight, maxHeight, selectedHeight]);

    const handleChangeCheckbox = (event) => {
        setLandusesCheckbox({ ...landusesCheckbox, [event.target.name]: event.target.checked });
        let mas = [];
        if (event.target.checked) {
            mas.push(event.target.name);
        }
        Object.keys(landusesCheckbox).forEach((key) => {
            if (key === event.target.name && !event.target.checked) {
                return;
            } else if (Boolean(landusesCheckbox[key])) {
                mas.push(key);
            }
        });
        dispatch(setHeightFilterZone(mas));
    };

    const handleSwitchChange = (event) => {
        let obj = { ...switches, [event.target.name]: event.target.checked };
        if (event.target.name === "showGains" && event.target.checked) {
            MixpanelService.track("gains_lense_on", {
                distinct_id: user.id,
                login: user.login,
                role: user.role === 2 ? "admin" : "user",
                region: regionInfo.title,
            });
            obj["showLosses"] = false;
            dispatch(setYears(regionInfo.gains.first_yr));
            dispatch(setYearsDiapozon(regionInfo.gains.first_yr));
        } else if (event.target.name === "showLosses" && event.target.checked) {
            MixpanelService.track("losses_lense_on", {
                distinct_id: user.id,
                login: user.login,
                role: user.role === 2 ? "admin" : "user",
                region: regionInfo.title,
            });
            obj["showGains"] = false;
            dispatch(setYears(regionInfo.losses.last_yr));
            dispatch(setYearsDiapozon(regionInfo.losses.last_yr));
        } else if ((event.target.name === "showLosses" || event.target.name === "showGains") && !event.target.checked) {
            MixpanelService.track("current_lense_on", {
                distinct_id: user.id,
                login: user.login,
                role: user.role === 2 ? "admin" : "user",
                region: regionInfo.title,
            });
            dispatch(setYears([]));
            dispatch(setYearsDiapozon([]));
        }
        dispatch(setLayerVisible(obj));
    };

    const handleHeightChange = (e, v) => {
        let min = -1;
        if (v[0] !== 0) {
            min = v[0];
        }
        let mas = [
            [">=", "li_2017_ma", min],
            ["<=", "li_2017_ma", v[1]],
        ];

        dispatch(setDiapozonHeight(mas));
    };

    const handleYearChange = (e, v) => {
        let mas = [];
        years.forEach((el, ind) => {
            if (ind >= v[0] && ind <= v[1]) {
                mas.push(el.label);
            }
        });
        dispatch(setYearsDiapozon(mas));
    };

    const getHeightSliderText = (v) => `${v}m`;
    return (
        <div>
            <div className={classes.filter}>
                <div className={classes.filter__head}>
                    <span>Land use</span>
                    <InfoTooltip text={LANDUSE_HELP} />
                </div>

                <div className={classes.zones}>
                    {landusesStore.map((z) => (
                        <div className={classes.land__item} style={{ width: "50%" }} key={z}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={heightFilterZone.includes(z)}
                                        onChange={handleChangeCheckbox}
                                        className={classes.land__checkbox}
                                        style={{ padding: 5 }}
                                        name={z}
                                        color="primary"
                                    />
                                }
                                label={z}
                            />
                        </div>
                    ))}
                </div>

                <div className={classes.filter__divider}></div>
            </div>

            <div className={classes.filter}>
                <div className={classes.filter__head}>
                    <span>Lenses</span>
                    <InfoTooltip text={LENSES_HELP} />
                </div>

                <div className={classes.land__item}>
                    <CurrentSwitch
                        size="small"
                        checked={switches.showPolygons}
                        onChange={handleSwitchChange}
                        name="showPolygons"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <span style={{ fontSize: 15 }}>{regionInfo["polygons"].label}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className={classes.land__item}>
                        <GainsSwitch
                            size="small"
                            checked={switches.showGains}
                            onChange={handleSwitchChange}
                            name="showGains"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        <span style={{ fontSize: 15 }}>{regionInfo["gains"].label}</span>
                    </div>

                    <div className={classes.land__item}>
                        <LossesSwitch
                            size="small"
                            checked={switches.showLosses}
                            onChange={handleSwitchChange}
                            name="showLosses"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        <span style={{ fontSize: 15 }}>{regionInfo["losses"].label}</span>
                    </div>
                </div>

                <div className={classes.filter__divider}></div>
            </div>

            <div className={classes.filter}>
                <div className={classes.filter__head}>
                    <span>Height</span>
                    <InfoTooltip text={HEIGHT_HELP} />
                </div>

                <div className={classes.height_min}>0m</div>

                <div className={classes.timeline_block}>
                    <Slider
                        value={selectedHeight}
                        onChange={(e, v) => setSelectedHeight(v)}
                        onChangeCommitted={handleHeightChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={getHeightSliderText}
                        className={classes.timeline_label_text}
                        step={1}
                        min={0}
                        max={Math.ceil(maxHeight)}
                    />
                </div>

                <div className={classes.height_max}>{Math.ceil(maxHeight)}m</div>

                <div className={classes.filter__divider}></div>
            </div>

            <div className={classes.filter}>
                <div className={classes.filter__head}>
                    <span>Year</span>
                    <InfoTooltip text={YEAR_HELP} />
                </div>

                <div className={classes.timeline_block}>
                    <Slider
                        disabled={!years.length}
                        value={selectedYear}
                        onChange={(e, v) => setSelectedYear(v)}
                        onChangeCommitted={handleYearChange}
                        className={classes.timeline}
                        step={null}
                        min={0}
                        max={years.length - 1}
                        marks={years}
                    />
                </div>
            </div>
        </div>
    );
};

export default FiltersBasic;
