import React, { useEffect } from "react";

import bbox from "@turf/bbox";
import { featureCollection } from "@turf/helpers";
import { Layer, Source, StaticMap, WebMercatorViewport } from "react-map-gl";
import { MAPBOX_TOKEN } from "../../config";
import ChartLineForPDF from "../GraphComponents/chartLineForPDF";

const treeStyle = {
    id: `poly3`,
    type: "fill",
    paint: {
        "fill-color": ["get", "color"],
        "fill-antialias": false,
        "fill-opacity": 1,
    },
};

const spanStyle = {
    position: "absolute",
    fontSize: "16px",
    marginTop: "-10px",
    color: "#888888",
    fontWeight: "bold",
    textTransform: "uppercase",
};

const squareStyles = (color) => ({
    width: "34px",
    height: "34px",
    background: color,
});

const PDFIndividual = React.forwardRef((props, ref) => {
    useEffect(() => {
        document.querySelector(`#print_butt_ind`).setAttribute("disabled", "disabled");
    }, []);

    if (!props.treeInfo) {
        let but = document.querySelector(`#print_butt_ind`);
        if (but && !but.hasAttribute("disabled")) {
            but.setAttribute("disabled", "disabled");
        }
        return null;
    }

    let features = props.treeInfo.geom.features;
    let collection = featureCollection(features);
    let boundBox = bbox(collection);
    let cornersLongLat = [
        [boundBox[0], boundBox[1]],
        [boundBox[2], boundBox[3]],
    ];

    let viewport = new WebMercatorViewport({ width: 400, height: 300 }).fitBounds(cornersLongLat, { padding: 30 });
    let { longitude, latitude, zoom } = viewport;
    //pdf_print_block нужен для показа компонента только на генерации PDF
    return (
        <div className="pdf_print_block" style={{ width: "80%", margin: "60px auto" }} ref={ref}>
            <div>
                <div>
                    <h3>CANOPY REPORT</h3>
                    <p>
                        Data Extracted {new Date().toDateString()} from <b>{props.region}</b> using LEAF
                    </p>
                    <p>
                        <b>Tree id:</b> {props.treeInfo.veg_id}{" "}
                    </p>
                    <p>
                        <b>Location:</b> [{longitude}, {latitude}]{" "}
                    </p>
                    <p>
                        <b>Classification:</b> {props.treeInfo.type}
                    </p>
                    <p>
                        <b>Landuse:</b> {props.treeInfo.landuse}{" "}
                    </p>
                    <hr></hr>
                </div>

                <div>
                    <h3>Map</h3>
                    <div style={{ width: "534px", height: "400px" }}>
                        <img id={"poly_img_ind"} src={null} alt={"map"} style={{ width: "100%", height: "100%" }} />
                        <StaticMap
                            width="100%"
                            height="100%"
                            visible={false}
                            attributionControl={false}
                            preserveDrawingBuffer={true}
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            latitude={latitude}
                            longitude={longitude}
                            zoom={zoom}
                            onLoad={(e) => {
                                document.querySelector(`#print_butt_ind`).removeAttribute("disabled");
                                document.querySelector(`#poly_img_ind`).src = e.target.getCanvas().toDataURL();
                            }}
                        >
                            {collection && (
                                <Source id={"poly3"} type="geojson" data={collection}>
                                    <Layer {...treeStyle} />
                                </Source>
                            )}
                        </StaticMap>
                    </div>
                </div>

                <div style={{ marginTop: 20, display: "flex", fontSize: 13 }}>
                    {features.map((f, ic) => (
                        <div key={ic}>
                            <div style={squareStyles(f.properties.color)}></div>
                            <div>{f.properties.year}</div>
                        </div>
                    ))}
                </div>

                <div className="page-break" />

                <div style={{ position: "relative", marginTop: "84px" }}>
                    <span style={spanStyle}>Growth</span>
                    <ChartLineForPDF data={props.treeInfo.graph} name={"year"} lines={[{ key: "value", label: "Canopy size", stroke: "#68A673" }]} individual={true}/>
                </div>

                <div className="page-footer">
                    Commercial-in-confidence | Player Piano Data Analytics | E: lab@ppdataanalytics.com | W: https://ppdataanalytics.com
                </div>
            </div>
        </div>
    );
});

export default PDFIndividual;
