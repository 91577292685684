import React from "react";
import {
    Checkbox,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    MenuItem,
    Typography,
    Toolbar,
    Tooltip,
    CircularProgress,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Select,
    Input,
    TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableHead from "@material-ui/core/TableHead";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { addRegionToUser, removeRegionFromUser, updatePassword, updateUser } from "../Redux/UserReducer";
import { capitalize } from "../Components/RegionComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        width: "100%",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    pointer: {
        textDecoration: "none",
        cursor: "pointer",
    },
    tableBody: {
        maxHeight: 560,
    },
    textSkeleton: {
        direction: "rtl",
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

export default function EnhancedTable({ page, setPage, headCells, rows, rowsLength, getUsers, deleteUser, meta }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selected, setSelected] = React.useState([]);
    const [selectedPerm, setSelectedPerm] = React.useState(null);
    const [selectedUserId, setSelectedUserId] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [newSelect, setNewSelect] = React.useState("none");
    const [isFetching, setIsFetching] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState("");

    const regions = useSelector((state) => state.regions.regions);
    const regions_ids = regions.map((r) => r.id);

    //костыль
    const getActualCount = (a1, a2) => a1.filter((v) => a2.includes(v)).length;

    const handleChangePage = (event, newPage) => {
        dispatch(getUsers(newPage + 1, meta.sort));
        setPage(newPage);
    };

    const onClickDelete = (idArr) => {
        idArr.map((id) => dispatch(deleteUser(id)));
        selected.length = 0;
    };

    const handleOpen = (el, mode = "region") => {
        setSelectedUserId(el.id);
        setSelectedPerm(el.access_regions);
        setModalOpen(mode);
    };

    const handleClose = () => {
        setModalOpen(false);
        setNewPassword("");
        setTimeout(() => setSelectedPerm(null), 200);
    };

    const handleChangePassword = () => {
        dispatch(updatePassword(selectedUserId, newPassword));
        setNewPassword("");
        handleClose();
    };

    const handleChange = (e, id) => {
        let checked = e.target.checked;
        if (checked) {
            setSelected([...selected, id]);
        } else {
            setSelected(selected.filter((el) => el !== id));
        }
    };

    const handleRemoveRegion = async (regionId) => {
        setIsFetching(true);
        let filteredPerm = selectedPerm.filter((p) => p !== regionId);
        setSelectedPerm(filteredPerm);
        await dispatch(removeRegionFromUser(selectedUserId, regionId));
        setIsFetching(false);
    };

    const setNewRole = (id, newValue) => {
        const ROLES_TO_ID = {
            Admin: 2,
            User: 1,
        };
        dispatch(
            updateUser(id, {
                role: ROLES_TO_ID[newValue],
            })
        );
    };

    const handeAddRegion = async () => {
        setSelectedPerm([...selectedPerm, newSelect]);
        setIsFetching(true);
        await dispatch(addRegionToUser(selectedUserId, newSelect));
        setNewSelect("none");
        setIsFetching(false);
    };

    const availableRegions = (selectedPerm && regions.filter((r) => !selectedPerm.includes(r.id))) || [];
    const isModalOpen = Boolean(modalOpen);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar
                    className={clsx(classes.root, {
                        [classes.highlight]: selected.length > 0 && deleteUser,
                    })}
                >
                    {selected.length > 0 && deleteUser ? (
                        <>
                            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                                {selected.length} selected
                            </Typography>
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" onClick={() => onClickDelete(selected)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                                {"Users"}
                            </Typography>
                        </>
                    )}
                </Toolbar>

                {rows.length ? (
                    <>
                        <TableContainer className={classes.tableBody}>
                            <Table className={classes.table} aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox"></TableCell>
                                        {headCells.map((column) => (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((el, index) => (
                                        <TableRow hover tabIndex={-1} key={index}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    onChange={(e) => handleChange(e, el.id)}
                                                    checked={selected.includes(el.id)}
                                                />
                                            </TableCell>

                                            {headCells.map((column) => (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id === "access_regions" ? (
                                                        el["role"] !== "Admin" ? (
                                                            <Button
                                                                onClick={() => handleOpen(el)}
                                                                color={el[column.id].length > 0 ? "primary" : "secondary"}
                                                            >
                                                                {" "}
                                                                {getActualCount(regions_ids, el[column.id])} Regions
                                                            </Button>
                                                        ) : (
                                                            ""
                                                        )
                                                    ) : column.id === "password" ? (
                                                        <Button onClick={() => handleOpen(el, "password")}>Change</Button>
                                                    ) : column.id === "role" ? (
                                                        <Select
                                                            style={{ minWidth: "80px" }}
                                                            value={el[column.id]}
                                                            onChange={(e) => setNewRole(el.id, e.target.value)}
                                                            input={<Input />}
                                                            MenuProps={MenuProps}
                                                        >
                                                            <MenuItem value={"Admin"}>Admin</MenuItem>
                                                            <MenuItem value={"User"}>User</MenuItem>
                                                        </Select>
                                                    ) : (
                                                        el[column.id]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={rowsLength}
                            rowsPerPage={15}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </>
                ) : (
                    <CircularProgress style={{ margin: "10px 30px" }} />
                )}
            </Paper>

            <Dialog open={isModalOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{modalOpen === "region" ? "Available Regions" : "Change Password"}</DialogTitle>

                {modalOpen === "region" ? (
                    <DialogContent>
                        {!isFetching ? (
                            <List>
                                {selectedPerm && selectedPerm.length ? (
                                    selectedPerm.map((el, index) => {
                                        let region = regions.find((r) => r.id === el);
                                        if (!region) {
                                            return null;
                                        }
                                        return (
                                            <ListItem key={index}>
                                                <ListItemText primary={capitalize(region.title)} />
                                                <ListItemSecondaryAction>
                                                    <IconButton size="small" onClick={() => handleRemoveRegion(el)}>
                                                        <DeleteIcon style={{ fontSize: "24px" }} />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })
                                ) : (
                                    <ListItem>
                                        <em>-No Regions-</em>
                                    </ListItem>
                                )}
                            </List>
                        ) : (
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <CircularProgress />
                            </div>
                        )}

                        {availableRegions.length ? (
                            <div style={{ marginTop: "30px", display: "flex", gap: "20px" }}>
                                <Select
                                    style={{ minWidth: "200px" }}
                                    value={newSelect}
                                    onChange={(e) => setNewSelect(e.target.value)}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="none">
                                        <em style={{ color: "grey" }}>Select region</em>
                                    </MenuItem>
                                    {availableRegions.map((el) => (
                                        <MenuItem key={el.id} value={el.id}>
                                            {el.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button
                                    onClick={handeAddRegion}
                                    color="primary"
                                    disabled={!(newSelect !== "none" && !isFetching)}
                                    size="small"
                                    variant="contained"
                                >
                                    Add
                                </Button>
                            </div>
                        ) : (
                            ""
                        )}
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <div style={{ width: 300 }}>
                            <TextField
                                label="Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                style={{ maxWidth: 200 }}
                            />
                            <Button
                                onClick={handleChangePassword}
                                disabled={newPassword.length < 3}
                                style={{ float: "right", marginTop: 10 }}
                                color="primary"
                                variant="contained"
                            >
                                Save
                            </Button>
                        </div>
                    </DialogContent>
                )}

                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
