import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button, MenuItem, Select } from "@material-ui/core";
import { useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DropzoneArea } from "material-ui-dropzone";
import { uploadShpAndGetGeojson } from "../../Redux/TreesReducer";

import { EditingMode } from "react-map-gl-draw";
import { setMode } from "../../Redux/TreesReducer";
import { setManyShapes } from "../../Redux/LeftSidebarReducer";

import truncate from "@turf/truncate";
import InfoTooltip from "./InfoTooltip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const HELP_TEXT = "The .zip file must contain at least the .shp, .shx, .dbf, and .prj files components of the shapefile.";

const UploadShpModal = ({ open, setOpen, setViewport, popoverClose, editor }) => {
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const [fields, setFields] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [file, setFile] = useState(null);

    const handleSelect = (e) => {
        setSelectedField(e.target.value);
    };

    const handleFileChange = async (input) => {
        if (!input.length) {
            return;
        }
        let file = input[0];
        setIsUploading(true);
        let data = await dispatch(uploadShpAndGetGeojson(file));
        let fields = [];
        Object.keys(data.features[0].properties).forEach((key) => {
            let val = data.features[0].properties[key];
            let type = typeof val;
            if (type === "string") {
                fields.push(key);
            }
        });
        setFile(data);
        setFields(fields);
        setIsUploading(false);
    };

    const handleAddToMap = async () => {
        if (!editor.current) {
            await dispatch(setMode(new EditingMode()));
        }
        let field = selectedField;
        let truncedFile = truncate(file, { coordinates: 2 });

        if (field) {
            truncedFile.features.forEach((f) => {
                f.properties = { title: f.properties[field], isVisible: true };
                //f.geometry = cleanCoords(f.geometry)
            });
        }

        dispatch(setManyShapes(truncedFile.features));
        editor.current.addFeatures(truncedFile.features);
        popoverClose();
        setOpen(false);
    };

    return (
        <>
            <Dialog fullWidth={true} maxWidth="xs" transitionDuration={300} open={open} onClose={() => setOpen(false)}>
                <div style={{ padding: "20px 30px 0 20px", display: "flex", alignItems: "center" }}>
                    <div style={{ fontSize: "20px", fontWeight: "600" }}>Upload file</div>
                    <InfoTooltip text={HELP_TEXT} />
                </div>

                <DialogContent>
                    {isUploading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <DialogContentText>Choose your shape file</DialogContentText>

                            {fields && (
                                <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
                                    <div>Select Name Field</div>
                                    <Select
                                        style={{ minWidth: "110px" }}
                                        value={selectedField || "none"}
                                        onChange={handleSelect}
                                        label={"land use"}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value="none">
                                            <em style={{ color: "grey" }}>None</em>
                                        </MenuItem>
                                        {fields.map((el, ind) => (
                                            <MenuItem key={ind} value={el}>
                                                {el}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}

                            {!file && (
                                <DropzoneArea
                                    onChange={handleFileChange}
                                    filesLimit={1}
                                    dropzoneClass="dropzone_my"
                                    dropzoneText="Drag and Drop your shape file (.zip)"
                                    maxFileSize={5242880}
                                    acceptedFiles={[".zip"]}
                                />
                            )}
                        </>
                    )}
                </DialogContent>

                <DialogActions style={{ margin: "5px 15px" }}>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Close
                    </Button>
                    <Button
                        disabled={!file || !selectedField || selectedField === "none"}
                        variant="contained"
                        onClick={handleAddToMap}
                        color="primary"
                    >
                        Add to map
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UploadShpModal;
