export const MAPBOX_TOKEN = "pk.eyJ1Ijoia2VtdXJvMTIiLCJhIjoiY2tvMDN1YW81MDc2ejJ1bm53ZTY2bGdrMyJ9.DJ0_bSsdjgDpaPeQ3kC6qw";

export const API_BASE_URL = {
    local: "http://localhost:5003/api",
    dev: "http://dev.api.treeledger.com.au",
    exp: "https://exp.api.treeledger.com.au",
    prod: "https://prod.api.treeledger.com.au",
}[process.env.REACT_APP_ENV];

export const MIXPANEL_TOKEN = "39f532f2cd1515cf92bc53462ec73ea7";

// export const API_BASE_URL = "https://dev.api.treeledger.com.au";

// export const MAPBOX_TOKEN = "pk.eyJ1Ijoia2VtdXJvMTIiLCJhIjoiY2tvMDN1YW81MDc2ejJ1bm53ZTY2bGdrMyJ9.DJ0_bSsdjgDpaPeQ3kC6qw";

// const isLocalhost = window.location.hostname === "localhost";
// const isDev = () => {
//     if (window.location.host.split(".")[0] === "app") {
//         return false;
//     } else if (window.location.host.split(".")[0] === "dev") {
//         return true;
//     }
// };

// export const API_BASE_URL = isLocalhost ? "http://localhost:5000/api" : isDev ? "https://dev.api.treeledger.com.au" : "https://api.treeledger.com.au/";
