import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "../config";

const MixpanelService = {
    isEnabled: () => {
        return process.env?.REACT_APP_ENV === "prod";
    },
    init: () => {
        if (MixpanelService.isEnabled() === true) {
            mixpanel.init(MIXPANEL_TOKEN);
        }
    },
    track: (eventName, data) => {
        if (MixpanelService.isEnabled() === true) {
            mixpanel.track(eventName, data);
        }
    },
};

export default MixpanelService;
