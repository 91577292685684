import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import NavBar from "../Modules/NavBar";
import { Auth } from "../Pages";
import AnalystProfile from "../Pages/AnalystProfile";
import MainContent from "../Pages/MainContent";
import RegionsMenu from "../Pages/RegionsMenu/RegionsMenu";
import UploadRegions from "../Pages/UploadRegion/UploadRegions";
import { initializeApp } from "../Redux/AppReducer";
import { hideSnackbar } from "../Redux/SnackbarReducer";
import { getAuthUserData } from "../Redux/UserReducer";

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        if (window.localStorage.getItem("auth_token")) {
            Promise.allSettled([dispatch(getAuthUserData())]).then(() => dispatch(initializeApp(true)));
        } else {
            dispatch(initializeApp(true));
        }
    }, [dispatch]);

    const isAuth = useSelector((state) => state.user.user);
    const isInitialized = useSelector((state) => state.app.isInitialized);

    const open = useSelector((state) => state.snackbar.isShow);
    const title = useSelector((state) => state.snackbar.title);
    const variant = useSelector((state) => state.snackbar.variant);
    const duration = useSelector((state) => state.snackbar.duration);

    const location = useLocation();
    const split = location.pathname.split("/");

    const handleSnackClose = () => {
        dispatch(hideSnackbar());
    };

    if (!isInitialized) {
        return null;
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={duration || 3000}
                open={open}
                onClose={handleSnackClose}
            >
                <Alert severity={variant || "info"} onClose={handleSnackClose}>
                    {title}
                </Alert>
            </Snackbar>

            {split[1] !== "region" && <NavBar />}
            {isAuth ? (
                <Switch>
                    <Route exact path="/" render={() => <RegionsMenu />} />
                    <Route path="/region/:region" render={() => <MainContent />} />

                    {isAuth.role === 2 && (
                        <>
                            <Route exact path="/profile" render={() => <AnalystProfile />} />
                            <Route exact path="/upload/:region" render={() => <UploadRegions />} />
                        </>
                    )}

                    <Route path="*" render={() => <Redirect to={"/"} />} />
                </Switch>
            ) : (
                <Switch>
                    <Route path="/" render={() => <Auth />} />
                </Switch>
            )}
        </>
    );
}

export default App;
