import Plot from "react-plotly.js";

const WaterfallChart = (props) => {
    const { data, preview = false, mode, polygonArea, regionArea } = props;

    const modifiedBase = (() => {
        if (mode === "polygon_area") {
            return ((data.waterfall.base / polygonArea) * 100).toFixed(2);
        } else if (mode === "total_area") {
            return ((data.waterfall.base / regionArea) * 100).toFixed(2);
        } else {
            return data.waterfall.base.toFixed(2);
        }
    })();

    const modifiedWaterfallData = (() => {
        if (mode === "polygon_area") {
            return data.waterfall.y.map((el) => ((el / polygonArea) * 100).toFixed(2));
        } else if (mode === "total_area") {
            return data.waterfall.y.map((el) => ((el / regionArea) * 100).toFixed(2));
        } else {
            return data.waterfall.y.map((el) => (+el).toFixed(2));
        }
    })();

    const modifiedYAxisTitle = (() => {
        if (mode === "polygon_area") {
            return "% of Polygon Area";
        } else if (mode === "total_area") {
            return "% of Region Area";
        } else {
            return "sqm";
        }
    })();

    const modifiedWaterfallText = (() => {
        if (mode === "polygon_area") {
            let text = [...data.waterfall.text];
            text[text.length - 1] = `${((100 * (data.waterfall.p1[data.waterfall.p1.length - 1] - data.waterfall.p1[0])) / polygonArea).toFixed(
                2
            )} %`;

            return text;
        } else if (mode === "total_area") {
            let text = [...data.waterfall.text];
            text[text.length - 1] = `${((100 * (data.waterfall.p1[data.waterfall.p1.length - 1] - data.waterfall.p1[0])) / regionArea).toFixed(2)} %`;

            return text;
        } else {
            let text = [...data.waterfall.text];
            text[text.length - 1] = `${(data.waterfall.p1[data.waterfall.p1.length - 1] - data.waterfall.p1[0]).toFixed(2)} sqm`;
            return text;
        }
    })();

    const waterfallData = [
        {
            x: data.waterfall.x,
            y: modifiedWaterfallData,
            measure: data.waterfall.measure,
            base: modifiedBase,
            text: modifiedWaterfallText,
            textangle: 0,
            textposition: "inside",
            type: "waterfall",
            decreasing: { marker: { color: "Maroon", line: { color: "red", width: 2 } } },
            increasing: { marker: { color: "Light Green", line: { color: "green", width: 2 } } },
            totals: { marker: { color: "deep sky blue", line: { color: "blue", width: 3 } } },
            showLegend: false,
        },
    ];

    const layout = {
        yaxis: {
            title: modifiedYAxisTitle,
        },
        xaxis: {
            automargin: true,
        },
        font: {
            size: 10,
        },
        width: (preview === true && 570) || 890,
        height: (preview === true && 300) || 750,
        showlegend: false,
        automargin: true,
    };

    return (
        <div>
            <Plot data={waterfallData} layout={layout} config={{ displaylogo: false }} />
        </div>
    );
};

// const WaterfallChart = (props) => {
//     const { data, preview = false, mode, polygonArea, regionArea } = props;


//     const modifiedBase = (() => {
//         if (mode === "polygon_area") {
//             return (data.base / polygonArea) * 100;
//         } else if (mode === "total_area") {
//             return (data.base / regionArea) * 100;
//         } else {
//             return data.base;
//         }
//     })();

//     const modifiedWaterfallData = (() => {
//         if (mode === "polygon_area") {
//             return data.y.map((el) => (el / polygonArea) * 100);
//         } else if (mode === "total_area") {
//             return data.y.map((el) => (el / regionArea) * 100);
//         } else {
//             return data.y;
//         }
//     })();

//     const modifiedYAxisTitle = (() => {
//         if (mode === "polygon_area") {
//             return "% of Polygon Area";
//         } else if (mode === "total_area") {
//             return "% of Region Area";
//         } else {
//             return "sqm";
//         }
//     })();

//     const waterfallData = [
//         {
//             x: {
//                 0: data.x_periods,
//                 1: data.x_types,
//             },
//             y: modifiedWaterfallData,
//             measure: data.measure,
//             base: modifiedBase,
//             text: data.text,
//             textangle: 0,
//             textposition: "inside",
//             type: "waterfall",
//             decreasing: { marker: { color: "Maroon", line: { color: "red", width: 2 } } },
//             increasing: { marker: { color: "Light Green", line: { color: "green", width: 2 } } },
//             totals: { marker: { color: "deep sky blue", line: { color: "blue", width: 3 } } },
//             showLegend: false,
//         },
//     ];

//     const layout = {
//         yaxis: {
//             title: modifiedYAxisTitle,
//         },
//         width: (preview === true && 570) || 880,
//         height: (preview === true && 300) || 500,
//         showlegend: false,
//     };

//     return (
//         <div>
//             <Plot data={waterfallData} layout={layout} config={{ displaylogo: false }} />
//         </div>
//     );
// };

export default WaterfallChart;
