import { EditingMode } from "react-map-gl-draw";
import { treesAPI } from "./API/Api";
import { showSnackbar } from "./SnackbarReducer";
import MixpanelService from "../services/mixpanel-service";
import {
    SET_CHARTS,
    SET_COMPARE_GRAPH,
    SET_CUSTOM_DATASET,
    SET_FETCHING,
    SET_FETCHING_PROGRESS,
    SET_HOVERED_YEAR_LAYER,
    SET_LAYER_VISIBLE,
    SET_LAYER_VISIBLE_CUSTOM,
    SET_MANY_CHARTS,
    SET_MANY_CHARTS_FOR_PDF,
    SET_MANY_TREES,
    SET_MAX_HEIGHT,
    SET_MODE,
    SET_TREE_INFO,
    SET_YEARS,
    TOGGLE_IS_COMPARE,
    TOGGLE_IS_OVERLAY,
    TREES_RESET,
    SET_ADDITIONAL_DATA,
} from "./types";

let initialState = {
    years: [],
    additionalData: null,
    maxHeight: 0,
    treeInfo: null,
    tressLayers: [],
    charts: null,
    chartsForPDF: null,
    mode: null,
    modeInfo: null,
    isFetching: false,
    fetchingProgress: null,
    isOverlay: true,
    isCompare: false,
    compareGraph: null,
    switches: {
        showPolygons: true,
        showGains: false,
        showLosses: false,
    },
    hoveredYearLayer: -1,
    customDatasets: {},
};

const treesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TREES_RESET:
            return initialState;
        case SET_FETCHING:
            return { ...state, isFetching: action.isFetching };
        case SET_FETCHING_PROGRESS:
            return { ...state, fetchingProgress: action.fetchingProgress };
        case TOGGLE_IS_OVERLAY:
            return { ...state, isOverlay: action.isOverlay };

        case SET_MODE:
            return { ...state, modeInfo: action.modeInfo, mode: action.mode };
        case SET_TREE_INFO:
            return { ...state, treeInfo: action.tree };

        case TOGGLE_IS_COMPARE:
            return { ...state, isCompare: action.isCompare };
        case SET_COMPARE_GRAPH:
            return { ...state, compareGraph: action.compareGraph };
        case SET_LAYER_VISIBLE:
            return { ...state, switches: { ...state.switches, ...action.switches } };

        case SET_LAYER_VISIBLE_CUSTOM: {
            let customDatasets = { ...state.customDatasets };
            customDatasets[action.name].isShow = action.isShow;
            return { ...state, customDatasets };
        }
        case SET_HOVERED_YEAR_LAYER:
            return { ...state, hoveredYearLayer: action.index };
        case SET_MANY_CHARTS_FOR_PDF:
            return { ...state, chartsForPDF: action.data };
        case SET_MAX_HEIGHT:
            return { ...state, maxHeight: action.maxHeight };

        case SET_CUSTOM_DATASET:
            return { ...state, customDatasets: { ...state.customDatasets, ...action.dataset } };

        case SET_MANY_TREES: {
            action.polygons.forEach((p) => (p.isVisible = true));
            return { ...state, tressLayers: [...action.polygons] };
        }

        case SET_CHARTS: {
            let newLayer = {
                polygons: action.polygons,
                isVisible: true,
            };
            if (state.tressLayers.length) {
                return { ...state, charts: action.charts, tressLayers: [...state.tressLayers, newLayer] };
            } else {
                return { ...state, charts: action.charts, tressLayers: [newLayer] };
            }
        }

        case SET_MANY_CHARTS: {
            let mas = [];
            action.polygons.forEach((el) => {
                mas.push({ polygons: el[0], isVisible: true });
            });
            return { ...state, charts: action.charts, tressLayers: mas };
        }

        case SET_YEARS: {
            action.years.sort((a, b) => a - b);
            let years = action.years.map((el, c) => ({ label: el, value: c }));

            return { ...state, years };
        }

        case SET_ADDITIONAL_DATA: {
            return { ...state, additionalData: action.data };
        }

        default:
            return state;
    }
};

//Поставить индекс какой слой подсвечивать
export const setHoveredYearLayer = (index) => ({ type: SET_HOVERED_YEAR_LAYER, index });
//Для восстановления слоев из сессии
export const setManyTreesLayers = (polygons) => ({ type: SET_MANY_TREES, polygons });
export const toggleIsOverlay = (isOverlay) => ({ type: TOGGLE_IS_OVERLAY, isOverlay });
export const setChartTrees = (charts, polygons) => ({ type: SET_CHARTS, polygons, charts });
export const setMaxHeight = (maxHeight) => ({ type: SET_MAX_HEIGHT, maxHeight });
//Копия данных слоев, для генерации pdf
export const setManyChartsForPDF = (data) => ({ type: SET_MANY_CHARTS_FOR_PDF, data });
//Сумма данных слоев
export const setManyChartTrees = (charts, polygons) => ({ type: SET_MANY_CHARTS, polygons, charts });
//Относится к Editor
export const setMode = (mode, modeInfo = null) => ({ type: SET_MODE, modeInfo, mode });
export const setFetching = (isFetching) => ({ type: SET_FETCHING, isFetching });
export const setFetchingProgress = (fetchingProgress) => ({ type: SET_FETCHING_PROGRESS, fetchingProgress });
export const setTreeInfo = (tree) => ({ type: SET_TREE_INFO, tree });
export const setYears = (years) => ({ type: SET_YEARS, years });
//Флаг что идет сравнение графиков
export const toggleIsCompare = (isCompare) => ({ type: TOGGLE_IS_COMPARE, isCompare });
export const setCompareGraph = (compareGraph) => ({ type: SET_COMPARE_GRAPH, compareGraph });
//Контроль свичами в header
export const setLayerVisible = (switches) => ({ type: SET_LAYER_VISIBLE, switches });
export const setLayerVisibleCustom = (name, isShow) => ({ type: SET_LAYER_VISIBLE_CUSTOM, name, isShow });

// New graphs

export const setAdditionalData = (data) => ({ type: SET_ADDITIONAL_DATA, data });

export const setCustomDataset = (dataset) => ({ type: SET_CUSTOM_DATASET, dataset });

const getFilters = (getState) => {
    let filters = getState().filters;

    const diapozonHeight = filters.diapozonHeight;
    const heightFilterZone = filters.heightFilterZone;
    const yearsDiapozon = filters.yearsDiapozon;

    //Если равны инициализационным значениям то не отправлять фильтр
    let heightFilter = [diapozonHeight[0][2], diapozonHeight[1][2]];
    if (diapozonHeight[0][2] === 0 && diapozonHeight[1][2] === 100) {
        heightFilter = null;
    }

    return {
        height: heightFilter,
        zones: heightFilterZone,
        years: yearsDiapozon,
    };
};

//Запрос за графиками при клике на 'ANALYSE' (одна область)
export const getTrees = (polygon) => {
    return async (dispatch, getState) => {
        try {
            dispatch(toggleIsCompare(false));
            dispatch(setManyChartTrees(null, []));
            dispatch(setManyChartsForPDF(null));
            dispatch(setTreeInfo(null));
            dispatch(setFetching(true));
            dispatch(setFetchingProgress(0));
            dispatch(setAdditionalData({}));

            let st = () => getState();
            let filters = getFilters(st);

            let obj = {
                region_id: st().regions.regionInfo.id,
                filters,
                data: {
                    type: polygon.type,
                    properties: polygon.properties,
                    geometry: polygon.geometry,
                },
            };
            let response = await treesAPI.getTrees(obj);

            let shape = {
                title: polygon.properties.title,
                data: response.data.data,
                elem: polygon,
            };

            let stat = st();
            MixpanelService.track("shape_analyze", {
                distinct_id: stat.user.user.id,
                login: stat.user.user.login,
                role: stat.user.user.role === 2 ? "admin" : "user",
                region: stat.regions.regionInfo.title,
                height: filters.height,
                years: filters.years,
                zones: filters.zones,
                shapes: [polygon.properties.title],
            });

            dispatch(setMode(new EditingMode()));
            dispatch(setManyChartsForPDF([shape]));
            dispatch(setChartTrees(response.data.data, polygon.geometry.coordinates[0]));
            dispatch(setAdditionalData(response.data.additionalData));
            dispatch(setFetching(false));
            return true;
        } catch (e) {
            console.log(e.response.message);
            dispatch(setFetching(false));
            dispatch(showSnackbar(e.response.data.message || e.message || "There are no trees", "error"));
            return false;
        }
    };
};

// Request to AI on click "ASK QUESTION" in analysis page

export const getAiSummary = (polygon, question, graph_type) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setFetching(true));
            // dispatch(setFetchingProgress(0));

            let st = () => getState();

            let filters = getFilters(st);

            let obj = {
                region_id: st().regions.regionInfo.id,
                filters,
                data: {
                    type: polygon.type,
                    properties: {},
                    geometry: polygon.geometry,
                },
                question: question,
                graph_type: graph_type,
            };
            let response = (await treesAPI.getAiSummary(obj)).data.summary;

            let stat = st();

            let additionalData = JSON.parse(JSON.stringify(stat.trees.additionalData));

            if (!additionalData.summary) {
                additionalData.summary = [];
            }

            additionalData.summary.push({
                text: response.text,
                graph_type: response.graph_type,
                question: response.question,
            });

            MixpanelService.track("talk_to_ai", {
                login: stat.user.user.login,
                user_id: stat.user.user.id,
                role: stat.user.user.role === 2 ? "admin" : "user",
                question: response.question,
                response: response.text,
            });

            dispatch(setAdditionalData(additionalData));
            dispatch(setFetching(false));
        } catch (e) {
            console.log(e);
            dispatch(setFetching(false));
            return false;
        }
    };
};

//Запрос за графиками при клике на 'ANALYSE' (много областей)
export const getManyTrees = (polygons, titles) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setManyChartsForPDF(null));
            if (polygons.length > 5) {
                dispatch(showSnackbar("You can choose up to 5 layers", "warning"));
                return false;
            }
            dispatch(setManyChartTrees(null, []));
            if (polygons.length > 1) {
                dispatch(compareGraphs(polygons, titles));
            }
            dispatch(setTreeInfo(null));
            dispatch(setFetching(true));
            dispatch(toggleIsOverlay(true));
            dispatch(setFetchingProgress(0));
            dispatch(setAdditionalData({}));

            let promises = [];
            let polygonCoords = [];

            let st = () => getState();
            let filters = getFilters(st);

            polygons.forEach((p) => {
                polygonCoords.push(p.geometry.coordinates[0]);
                promises.push(
                    new Promise(async (resolve) => {
                        let obj = {
                            region_id: st().regions.regionInfo.id,
                            filters,
                            data: {
                                type: p.type,
                                properties: {},
                                geometry: p.geometry,
                            },
                        };
                        resolve(treesAPI.getTrees(obj));
                    })
                );
            });
            let landuse = await Promise.all(promises);
            let final = {};
            let shapes = [];
            //Для конкатенации объектов в общий
            for (let i = 0; i < landuse.length; i++) {
                shapes.push({
                    title: titles[i],
                    data: landuse[i].data.data,
                    elem: polygons[i],
                });

                Object.keys(landuse[i].data.data).forEach((k1) => {
                    if (!final.hasOwnProperty(k1)) {
                        final[k1] = {};
                    }
                    Object.keys(landuse[i].data.data[k1]).forEach((k2) => {
                        if (!final[k1].hasOwnProperty(k2)) {
                            final[k1][k2] = {};
                        }
                        Object.keys(landuse[i].data.data[k1][k2]).forEach((k3) => {
                            if (!final[k1][k2].hasOwnProperty(k3)) {
                                final[k1][k2][k3] = 0;
                            }
                            final[k1][k2][k3] += landuse[i].data.data[k1][k2][k3];
                        });
                    });
                });
            }

            dispatch(setMode(new EditingMode()));
            //Отдельно сохранить статистику каждой области (нужно для генерации PDF)
            dispatch(setManyChartsForPDF(shapes));
            dispatch(setAdditionalData(landuse[0].data.additionalData));
            dispatch(setManyChartTrees(final, polygonCoords));
            dispatch(toggleIsOverlay(false));
            dispatch(setFetching(false));

            let stat = st();

            MixpanelService.track("shape_analyze", {
                distinct_id: stat.user.user.id,
                login: stat.user.user.login,
                role: stat.user.user.role === 2 ? "admin" : "user",
                region: stat.regions.regionInfo.title,
                height: filters.height,
                years: filters.years,
                zones: filters.zones,
                shapes: titles,
            });

            return true;
        } catch (e) {
            console.log(e);
            dispatch(setFetching(false));
            dispatch(toggleIsOverlay(false));
            dispatch(showSnackbar(e.response.data.message || e.message || "There are no trees", "error"));
            return false;
        }
    };
};

//Костыль для обновления карты
export const localChangeIsSelected = () => {
    return async (dispatch) => {
        dispatch(setFetching(true));
        await new Promise((r) => setTimeout(r, 0));
        dispatch(setFetching(false));
    };
};

//Цветовая палитра годов
const COLORS = ["#68a673", "#73b17d", "#7fbc88", "#8bc794", "#99d1a0", "#a9dbad", "#bae5bb", "#cfeecb", "#e7f6db", "#F3FFE7", "#fffee9"];

export const getTreeInfoById = (region_id, id) => {
    return async (dispatch) => {
        try {
            dispatch(setTreeInfo(null));
            dispatch(toggleIsOverlay(true));
            let response = await treesAPI.getTreeById(region_id, id);
            let graph = [];
            Object.keys(response.data.data.graph).forEach((key) => {
                graph.push({ year: key, value: parseFloat(response.data.data.graph[key]) });
            });
            response.data.data.graph = graph;
            response.data.data.geom.features.forEach((el, ind) => {
                el.properties.color = COLORS[Math.min(ind, COLORS.length - 1)];
                //el.properties.opacity = ((response.data.data.geom.features.length - 1) - ind) / 10
            });
            dispatch(setTreeInfo(response.data.data));
            dispatch(showSnackbar("Success get info by id = " + id, "success"));

            dispatch(toggleIsOverlay(false));
            return true;
        } catch (e) {
            console.log(e);
            dispatch(toggleIsOverlay(false));
            dispatch(showSnackbar("The tree not found", "error"));
            return false;
        }
    };
};

export const compareGraphs = (poly, titles) => {
    if (poly.length < 2) {
        return true;
    }
    return async (dispatch, getState) => {
        try {
            dispatch(setCompareGraph(null));
            dispatch(toggleIsCompare(true));
            const polygons = poly.map((el, ind) => ({
                type: "Feature",
                properties: {
                    title: `${titles[ind]}`,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: el.geometry.coordinates,
                },
            }));

            const filters = getFilters(getState);

            const obj = {
                region_id: getState().regions.regionInfo.id,
                filters,
                data: {
                    type: "FeatureCollection",
                    features: polygons,
                },
            };

            const response = await treesAPI.compareGraph(obj);
            const data = response.data.data;
            const newData = {};
            Object.keys(data).forEach((key) => {
                newData[key] = [];
                Object.keys(data[key]).forEach((year) => {
                    newData[key].push({ year, ...data[key][year] });
                });
            });

            dispatch(setCompareGraph(newData));
            dispatch(toggleIsCompare(true));
            return true;
        } catch (e) {
            console.log(e);
            dispatch(toggleIsCompare(true));
            dispatch(showSnackbar("Something went wrong", "error"));
            return false;
        }
    };
};

export const uploadShpAndGetGeojson = (file) => {
    return async (dispatch) => {
        try {
            let formData = new FormData();
            formData.append("file", file);
            let response = await treesAPI.uploadShpAndGetGeojson(formData);
            dispatch(showSnackbar("Shape file was uploaded", "success"));
            return response.data;
        } catch (e) {
            console.log(e);
            dispatch(showSnackbar("Something went wrong", "error"));
            return false;
        }
    };
};

export default treesReducer;
