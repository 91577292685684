import React, { useEffect } from "react";
import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LoginForm, Table } from "../../Modules";
import { register, getUsers, deleteUser } from "../../Redux/UserReducer";
import RegionComponent from "../../Components/RegionComponent";

const headCells = [
    { id: "login", numeric: false, disablePadding: true, label: "Login" },
    { id: "password", numeric: false, disablePadding: true, label: "Password" },
    { id: "role", numeric: true, disablePadding: false, label: "Role" },
    { id: "access_regions", label: "Permissions" },
    { id: "createdAt", numeric: true, disablePadding: false, label: "Created" },
];

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    paper: {
        maxWidth: 460,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
    },
}));

const getParsedDate = (time) => {
    let date = new Date(time);
    let y = date.getFullYear(),
        mo = date.getMonth() + 1,
        d = date.getDate(),
        h = date.getHours(),
        m = date.getMinutes();

    if (mo < 10) {
        mo = "0" + mo;
    }
    if (d < 10) {
        d = "0" + d;
    }
    if (h < 10) {
        h = "0" + h;
    }
    if (m < 10) {
        m = "0" + m;
    }

    return `${d}.${mo}.${y} ${h}:${m}`;
};

const AnalystProfile = () => {
    const classes = useStyles();
    const user = useSelector((state) => state.user.user);
    const users = useSelector((state) => state.user.users);
    const RowsLength = useSelector((state) => state.user.countUsers);
    const onSubmit = (login, pass) => {
        dispatch(register(login, pass));
    };
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    let data = [];

    users.forEach((u) =>
        data.push({
            ...u,
            role: u.role === 2 ? "Admin" : "User",
            createdAt: getParsedDate(u.createdAt),
        })
    );

    return (
        <div style={{ overflow: "hidden" }}>
            <Container maxWidth="sm" component="main" className={classes.heroContent}>
                <RegionComponent />
            </Container>

            {user.role === 2 ? (
                <Container maxWidth="lg">
                    <Table
                        headCells={headCells}
                        rows={data}
                        page={page}
                        setPage={setPage}
                        rowsLength={RowsLength}
                        getUsers={getUsers}
                        deleteUser={deleteUser}
                        tableName="Analysts"
                    />
                </Container>
            ) : null}

            {user.role === 2 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography component="h1" variant="h5" align="center">
                            Register user
                        </Typography>
                        <LoginForm name="Register" onSubmit={onSubmit} />
                    </Paper>
                </div>
            ) : null}
        </div>
    );
};

export default AnalystProfile;
