export const SET_USER_DATA = "users/SET_USER_DATA";
export const SET_IS_AUTH = "users/SET_IS_AUTH";
export const TOGGLE_IS_FETCHING = "users/TOGGLE_IS_FETCHING";
export const SET_USERS_DATA = "users/SET_USERS_DATA";
export const ADD_REGION_TO_USER = "users/ADD_REGION";
export const REMOVE_REGION_FROM_USER = "users/REMOVE_REGION";

export const APP_INITIALIZED = "app/APP_INITIALIZED";

export const SET_TREES = "trees/SET_TREES";
export const SET_CHARTS = "tress/SET_CHARTS";
export const SET_MODE = "trees/SET_MODE";
export const SET_FETCHING = "trees/SET_FETCHING";
export const SET_MANY_TREES = "trees/SET_MANY_TREES";
export const SET_FETCHING_PROGRESS = "trees/SET_FETCHING_PROGRESS";
export const TOGGLE_IS_VISIBLE = "trees/TOGGLE_IS_VISIBLE";
export const TOGGLE_IS_OVERLAY = "trees/TOGGLE_IS_OVERLAY";
export const SET_TREE_INFO = "trees/SET_TREE_INFO";
export const SET_YEARS = "trees/SET_YEARS";
export const SET_MANY_CHARTS = "trees/SET_MANY_CHARTS";
export const TOGGLE_IS_COMPARE = "trees/TOGGLE_IS_COMPARE";
export const SET_COMPARE_GRAPH = "trees/SET_COMPARE_GRAPH";
export const SET_LAYER_VISIBLE = "trees/SET_LAYER_VISIBLE";
export const SET_HOVERED_YEAR_LAYER = "trees/SET_HOVERED_YEAR_LAYER";
export const SET_MANY_CHARTS_FOR_PDF = "trees/SET_MANY_CHARTS_FOR_PDF";
export const SET_MAX_HEIGHT = "trees/SET_MAX_HEIGHT";
export const SET_CUSTOM_DATASET = "trees/SET_CUSTOM_DATASET";
export const SET_SHP = "trees/SET_SHP";
export const SET_LAYER_VISIBLE_CUSTOM = "trees/SET_LAYER_VISIBLE_CUSTOM";
export const SET_ADDITIONAL_DATA = "trees/SET_ADDITIONAL_DATA";

export const SET_MANY_SHAPES = "sidebar/SET_MANY_SHAPES";
export const SET_SHAPE = "sidebar/SET_SHAPE";
export const REMOVE_SHAPE = "sidebar/REMOVE_SHAPE";
export const SET_VISIBLE_SHAPE = "sidebar/SET_VISIBLE_SHAPE";
export const SET_SHAPE_NAME = "sidebar/SET_SHAPE_NAME";
export const SET_SELECTED_FEATURES = "sidebar/SET_SELECTED_FEATURES";

export const SET_SESSIONS = "session/SET_SESSIONS";
export const SET_MY_SESSIONS = "session/SET_MY_SESSIONS";
export const REMOVE_SESSION = "session/REMOVE_SESSION";
export const ADD_SESSION = "session/ADD_SESSION";
export const SET_SESSION_FETCHING = "session/SET_SESSION_FETCHING";
export const UPDATE_SESSION = "session/UPDATE_SESSION";

export const SHOW_SNACKBAR = "snackbar/SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "snackbar/HIDE_SNACKBAR";
export const TOGGLE_SNACKBAR = "snackbar/TOGGLE_SNACKBAR";

export const SET_REGION_INFO = "regions/SET_REGION_INFO";
export const SET_REGIONS = "regions/SET_REGIONS";
export const SET_UPLOAD_PROGRESS = "regions/SET_UPLOAD_PROGRESS";
export const ADD_REGION = "regions/ADD_REGION";
export const REMOVE_REGION = "regions/REMOVE_REGION";
export const UPDATE_REGION = "regions/UPDATE_REGION";
export const SET_FILES = "regions/SET_FILES";

export const SET_POLYGONS_LOADED = "filters/SET_POLYGONS_LOADED";
export const SET_DIAPOZON_HEIGHT = "filters/SET_DIAPOZON_HEIGHT";
export const SET_HEIGHT_FILTER_ZONE = "filters/SET_HEIGHT_FILTER_ZONE";
export const SET_LANDUSES = "filters/SET_LANDUSES";
export const SET_YEARS_DIAPOZON = "filters/SET_YEARS_DIAPOZON";
export const SET_DIAPOZON_HEIGHT_CUSTOM = "filters/SET_DIAPOZON_HEIGHT_CUSTOM";
export const SET_HEIGHT_FILTER_ZONE_CUSTOM = "filters/SET_HEIGHT_FILTER_ZONE_CUSTOM";
export const SET_YEARS_DIAPOZON_CUSTOM = "filters/SET_YEARS_DIAPOZON_CUSTOM";

//RESET
export const REGION_RESET = "regions/RESET";
export const SESSIONS_RESET = "session/RESET";
export const FILTERS_RESET = "filters/RESET";
export const TREES_RESET = "trees/RESET";
export const SIDEBAR_RESET = "trees/RESET";
