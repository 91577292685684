import clsx from "clsx";
import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Graph } from "../GraphComponents/graph";

import { Button, CircularProgress } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { CompareGraph } from "../GraphComponents/CompareGraph";
import { IndividualGraph } from "../GraphComponents/IndividualGraph";
import PDFCompareComponent from "./PDFCompareComponent";
import PDFComponent from "./PDFComponent";
import PDFIndividual from "./PDFIndividual";
import PDFAiSummaryComponent from "./PDFAiSummaryComponent";
import MixpanelService from "../../services/mixpanel-service";

import area from "@turf/area";

import jszip from "jszip";

const drawerWidth = 900; // 520 old value

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: "90%",
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        position: "absolute",
        top: 50,
        right: 10,
        zIndex: 1,
        color: "white",
        borderColor: "white",
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        marginLeft: -drawerWidth,
    },
    drawerPaper: {
        width: drawerWidth,
        color: "white",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        background: "white",
        color: "rgb(65,65,65)",
        padding: theme.spacing(3, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        position: "absolute",
        flexDirection: "column",
        overflowX: "hidden",
        justifyContent: "flex-end",
        fontWeight: "700",
        width: "100%",
    },
    drawerHeaderTitle: {
        color: "rgb(255,3,3)",
        fontWeight: "700",
    },
    active: {
        background: "white !important",
        color: "rgb(252,66,54)",
    },
    paper: {
        borderRadius: "0 !important",
        boxShadow: "none",
    },
    popoverList: {
        padding: theme.spacing(2, 4),
    },
    drawerIcon: {
        position: "absolute",
        zIndex: 100,
        left: "8px",
        top: "84px",
    },
    drawerContent: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
    },
    drawerPrintPdf: {
        margin: "40px 0 0 0",
        display: "flex",
        justifyContent: "center",
    },
    analyseButton: {
        border: "none",
        color: "white",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        padding: "6px 16px",
        fontSize: "0.875rem",
        minWidth: "64px",
        boxSizing: "border-box",
        transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: "500",
        lineHeight: "1.75",
        borderRadius: "4px",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        background: "#FC4236",
        "&:hover": {
            background: "#FC4236",
            cursor: "pointer",
        },
        "&:disabled": {
            cursor: "default",
            color: "rgba(0, 0, 0, 0.26)",
            boxShadow: "none",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
        "&:active": {
            boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
        },
    },
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: "100%" }}
        >
            {value === index && (
                <Box>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const COLORS = ["#5E8F66", "#BA456A", "#3499CF", "#A39B92", "#EF463B", "#8A2040"];

const parseRawCharts = (charts) => {
    if (!charts) {
        return { dataPie: null, lineGraphData: null, dataBar: null };
    }
    const dataPie = Object.keys(charts.landuse_pie_chart).map((item) => ({
        name: item,
        value: charts.landuse_pie_chart[item]["properties.Shape_Area"].toFixed(2),
    }));

    const dataLine = Object.keys(charts.veg_linegraph).map((item) => ({
        name: item,
        existing: charts.veg_linegraph[item]["existing_vegetaion"],
        total: charts.veg_linegraph[item]["total_vegetation"],
    }));

    const dataBar = Object.keys(charts.loss_gain_graph).map((item) => ({
        name: item,
        Gain: charts.loss_gain_graph[item]["Gain"],
        Loss: -charts.loss_gain_graph[item]["Loss"],
    }));

    let sum = 0;

    let dataBarWithNet = JSON.parse(JSON.stringify(dataBar));

    dataBarWithNet.forEach((el) => {
        Object.keys(el).forEach((key) => {
            if (key === "name") {
                return;
            }
        });
        sum += el["Gain"] + el["Loss"];
        el["Net"] = sum;
    });

    return {
        dataPie,
        dataLine,
        dataBar: dataBarWithNet,
    };
};

const parsePlotlyCharts = (data) => {
    if (!data || Object.keys(data).length === 0) {
        return { dataScatter: null, dataBar: null };
    }

    const status = data.status;

    const lastYear = data.last_year;

    const currentCanopy = data.current_canopy;

    const startingCanopy = data.starting_canopy;

    const contribution = data.contribution;

    const changeRatio = data.change_ratio;

    const firstYear = data.first_year;

    const summary = data.summary;

    const dataScatter = {
        x: [],
        y: [],
    };

    const dataLossGains = {
        x: [],
        time_period: [],
        plantings: [],
        removals: [],
        net: [],
    };

    const dataTotalArea = {
        shape_area: [],
        year: [],
        type2: [],
        landuse: [],
        text_area: [],
    };

    const dataVegetationRemovals = {
        year: [],
        type2: [],
        landuse: [],
        text_area: [],
        shape_area: [],
    };

    const dataTreeHeights = {
        li_class: [],
        shape_area: [],
        prank: [],
        text_area: [],
    };

    const dataTreeHeightsAboveGround = {
        li_class: [],
        landuse: [],
        shape_area: [],
        prank: [],
        text_area: [],
    };

    const dataWaterfall = JSON.parse(JSON.stringify(data.waterfall_graph));

    Object.keys(data.scatter_graph.year).forEach((key, id) => {
        dataScatter.x.push(data.scatter_graph.year[id]);
        dataScatter.y.push(data.scatter_graph.shape_area[id]);
    });

    Object.keys(data.council_graph.x).forEach((key, id) => {
        dataLossGains.x.push(data.council_graph.x[id]);
        dataLossGains.time_period.push(data.council_graph.time_period[id]);
        dataLossGains.plantings.push(data.council_graph.plantings[id]);
        dataLossGains.removals.push(data.council_graph.removals[id]);
        dataLossGains.net.push(data.council_graph.net[id]);
    });

    Object.keys(data.total_area_graph.year).forEach((key, id) => {
        dataTotalArea.shape_area.push(data.total_area_graph.shape_area[id]);
        dataTotalArea.year.push(data.total_area_graph.year[id]);
        dataTotalArea.type2.push(data.total_area_graph.type2[id]);
        dataTotalArea.landuse.push(data.total_area_graph.landuse[id]);
        dataTotalArea.text_area.push(data.total_area_graph.text_area[id]);
    });

    Object.keys(data.removals_graph.year).forEach((key, id) => {
        dataVegetationRemovals.shape_area.push(data.removals_graph.shape_area[id]);
        dataVegetationRemovals.type2.push(data.removals_graph.type2[id]);
        dataVegetationRemovals.landuse.push(data.removals_graph.landuse[id]);
        dataVegetationRemovals.text_area.push(data.removals_graph.text_area[id]);
        dataVegetationRemovals.year.push(data.removals_graph.year[id]);
    });

    Object.keys(data.tree_heights_graph.prank).forEach((key, id) => {
        dataTreeHeights.li_class.push(data.tree_heights_graph.Li_Class[id]);
        dataTreeHeights.shape_area.push(data.tree_heights_graph.shape_area[id]);
        dataTreeHeights.text_area.push(data.tree_heights_graph.text_area[id]);
        dataTreeHeights.prank.push(data.tree_heights_graph.prank[id]);
    });

    Object.keys(data.height_above_ground_graph.prank).forEach((key, id) => {
        dataTreeHeightsAboveGround.li_class.push(data.height_above_ground_graph.Li_Class[id]);
        dataTreeHeightsAboveGround.shape_area.push(data.height_above_ground_graph.shape_area[id]);
        dataTreeHeightsAboveGround.text_area.push(data.height_above_ground_graph.text_area[id]);
        dataTreeHeightsAboveGround.prank.push(data.height_above_ground_graph.prank[id]);
        dataTreeHeightsAboveGround.landuse.push(data.height_above_ground_graph.landuse[id]);
    });

    const sortedTreeHeightsPrank = JSON.parse(JSON.stringify(dataTreeHeights.prank)).sort((a, b) => a - b);

    const sortedTreeHeightsData = {
        li_class: [],
        shape_area: [],
        prank: [],
        text_area: [],
    };

    sortedTreeHeightsPrank.forEach((i, id) => {
        const oldId = dataTreeHeights.prank.findIndex((el) => el === i);

        sortedTreeHeightsData.li_class.push(dataTreeHeights.li_class[oldId]);
        sortedTreeHeightsData.shape_area.push(dataTreeHeights.shape_area[oldId]);
        sortedTreeHeightsData.text_area.push(dataTreeHeights.text_area[oldId]);
        sortedTreeHeightsData.prank.push(dataTreeHeights.prank[oldId]);
    });

    return {
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataVegetationRemovals,
        dataTreeHeights: sortedTreeHeightsData,
        dataTreeHeightsAboveGround,
        dataWaterfall,
        status,
        lastYear,
        currentCanopy,
        startingCanopy,
        contribution,
        summary,
        changeRatio,
        firstYear,
    };
};

const getFilters = (filters) => {
    let diapozonHeight = filters.diapozonHeight;
    let heightFilterZone = filters.heightFilterZone;
    let yearsDiapozon = filters.yearsDiapozon;

    //Если равны инициализационным значениям то не отправлять фильтр
    let heightFilter = [diapozonHeight[0][2], diapozonHeight[1][2]];
    if (!yearsDiapozon.length) {
        //хардкод для current так как нет годов
        yearsDiapozon = `${2009} - ${2020}`;
    }

    return {
        height: heightFilter,
        zones: heightFilterZone,
        years: yearsDiapozon,
    };
};

const getIndCsv = (data, veg_id) => {
    if (!data?.length) {
        return [];
    }
    let csvData = [];
    csvData.push(["year", ...data.map((d) => d.year)]);
    csvData.push([`${veg_id} (sqm)`, ...data.map((d) => d.value)]);
    return csvData;
};

const processRow = function (row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
            result = '"' + result + '"';
        }
        if (j > 0) {
            finalVal += ",";
        }
        finalVal += result;
    }
    return finalVal + "\n";
};

const getCsvBlob = (array) => {
    let csvFile = "";
    for (var i = 0; i < array.length; i++) {
        csvFile += processRow(array[i]);
    }
    return new Blob([csvFile], { type: "text/csv;charset=utf-8" });
};

const getTotalArea = (data) => {
    let totalArea = 0;
    data.forEach((item) => {
        if (item.elem.geometry.coordinates[0]) {
            totalArea += area(item.elem);
        } else {
            console.log(item.elem.geometry.coordinates[0]);
        }
    });
    return totalArea;
};

const RightSidebar = React.memo(({ region, open, handleDrawerOpen, handleDrawerClose }) => {
    const theme = useTheme();
    const classes = useStyles();
    const componentRef = useRef();
    const PDFRef = useRef();
    const AiSummaryPDFRef = useRef();

    const [tab, setTab] = useState(0);

    // THE MODE WILL DETERMINE HOW THE CHART WILL BE SHOWN, MODE CAN BE CHANGED IN THE DROPDOWN
    const [yAxisMode, setYAxisMode] = useState("polygon_area");

    const polygons = useSelector((state) => state.trees.chartsForPDF);
    const treesCharts = useSelector((state) => state.trees.charts);
    const isCompare = useSelector((state) => state.trees.isCompare);
    const compareGraph = useSelector((state) => state.trees.compareGraph);
    const treeInfo = useSelector((state) => state.trees.treeInfo);
    const additionalChartsData = useSelector((state) => state.trees.additionalData);
    const filters = getFilters(useSelector((state) => state.filters));
    const regionInfo = useSelector((state) => state.regions.regionInfo);

    const user = useSelector((state) => state.user.user);

    const chartsForPDF = useSelector((state) => state.trees.chartsForPDF);

    let { dataPie, dataLine, dataBar } = parseRawCharts(treesCharts);

    const {
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataVegetationRemovals,
        dataTreeHeights,
        dataTreeHeightsAboveGround,
        dataWaterfall,
        status,
        lastYear,
        currentCanopy,
        startingCanopy,
        contribution,
        summary,
        changeRatio,
        firstYear,
    } = parsePlotlyCharts(additionalChartsData);

    let agregateGraph = {
        dataPie,
        dataLine,
        dataBar,
        dataScatter,
        dataLossGains,
        dataTotalArea,
        dataVegetationRemovals,
        dataTreeHeights,
        dataTreeHeightsAboveGround,
        dataWaterfall,
    };

    let keys = [];
    let yearsDiap = `All`;
    if (dataLine && dataLine.length) {
        yearsDiap = `${dataLine[0].name} - ${dataLine[dataLine.length - 1].name}`;
    }

    let csvData = [];
    if (treeInfo) {
        csvData = getIndCsv(treeInfo.graph, treeInfo.veg_id);
    }

    if (compareGraph) {
        keys = [];
        //keys = массив линий key - название линии, stroke - цвет линии
        Object.keys(compareGraph.existing_veg[0]).forEach((key, ind) => {
            if (key === "name" || key === "year") {
                return;
            }
            keys.push({
                key,
                stroke: COLORS[ind % COLORS.length],
            });
        });
    }

    const checkIfAllGraps = () => {
        if (
            (dataScatter,
            dataLossGains,
            dataTotalArea,
            dataVegetationRemovals,
            dataTreeHeights,
            dataWaterfall,
            status,
            lastYear,
            currentCanopy,
            startingCanopy,
            contribution,
            summary,
            changeRatio,
            firstYear)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const checkIfAllCompareGraphs = () => {
        if (compareGraph) {
            if (compareGraph.cumalative_loss_gain && compareGraph.existing_veg && compareGraph.total_veg) {
                return true;
            }
        } else {
            return false;
        }
    };

    const handleGenerateCSV = () => {
        let landUse = [["Land Use", "Percent"]];
        let sum = 0;

        let lossGain = [];
        let canopyCover = [];

        let lossGainV2 = [];
        let scatter = [];
        let plantingsByLanduse = [];
        let treeHeights = [];
        let waterfall = [];

        lossGainV2.push(["Time period", ...dataLossGains.time_period]);
        lossGainV2.push(["Plantings", ...dataLossGains.plantings]);
        lossGainV2.push(["Removals", ...dataLossGains.removals]);
        lossGainV2.push(["Net", ...dataLossGains.net]);

        scatter.push(["Year", ...dataScatter.x]);
        scatter.push(["% of Polygon Area", ...dataScatter.y]);

        plantingsByLanduse.push(["Year", ...dataTotalArea.year]);
        plantingsByLanduse.push(["Landuse", ...dataTotalArea.landuse]);
        plantingsByLanduse.push(["Type", ...dataTotalArea.type2]);
        plantingsByLanduse.push(["% of Polygon Area", ...dataTotalArea.shape_area]);
        plantingsByLanduse.push(["% of Polygon Area of Second Type", ...dataTotalArea.text_area]);

        treeHeights.push(["Tree height", ...dataTreeHeights.li_class]);
        treeHeights.push(["% of Polygon Area", ...dataTreeHeights.text_area]);
        treeHeights.push(["Ratio to Polygon Area", ...dataTreeHeights.shape_area]);
        treeHeights.push(["Prank", ...dataTreeHeights.prank]);

        waterfall.push(["Time Period", ...dataWaterfall.waterfall.x[0]]);
        waterfall.push(["Type", ...dataWaterfall.waterfall.x[1]]);
        waterfall.push(["% of Total Council", ...dataWaterfall.waterfall.y]);
        waterfall.push(["Status", ...dataWaterfall.waterfall.text]);
        waterfall.push(["Measure", ...dataWaterfall.waterfall.measure]);
        waterfall.push(["Base", dataWaterfall.waterfall.base]);

        if (yAxisMode === "sqm") {
            dataPie.forEach((d) => (sum += +d.value));
            dataPie.forEach((d) => {
                landUse.push([d.name, parseFloat((d.value / sum).toFixed(4)) * 100 + "%"]);
            });

            lossGain.push(["Year", ...dataBar.map((d) => d.name)]);
            lossGain.push(["Gain (sqm)", ...dataBar.map((d) => d.Gain)]);
            lossGain.push(["Loss (sqm)", ...dataBar.map((d) => d.Loss)]);
            lossGain.push(["Net (sqm)", ...dataBar.map((d) => d.Net)]);

            canopyCover.push(["Year", ...dataLine.map((d) => d.name)]);
            canopyCover.push(["Existing (sqm)", ...dataLine.map((d) => d.existing)]);
            canopyCover.push(["Total (sqm)", ...dataLine.map((d) => d.total)]);
        }

        if (yAxisMode === "total_area") {
            dataPie.forEach((d) => (sum += +d.value));
            dataPie.forEach((d) => {
                landUse.push([d.name, parseFloat((d.value / sum).toFixed(4)) * 100 + "%"]);
            });

            lossGain.push(["Year", ...dataBar.map((d) => d.name)]);
            lossGain.push(["Gain (% of total Area)", ...dataBar.map((d) => (100 * d.Gain) / regionInfo.area.value)]);
            lossGain.push(["Loss (% of total Area)", ...dataBar.map((d) => (100 * d.Loss) / regionInfo.area.value)]);
            lossGain.push(["Net (% of total Area)", ...dataBar.map((d) => (100 * d.Net) / regionInfo.area.value)]);

            canopyCover.push(["Year", ...dataLine.map((d) => d.name)]);
            canopyCover.push(["Existing (% of Polygon Area)", ...dataLine.map((d) => (100 * d.existing) / regionInfo.area.value)]);
            canopyCover.push(["Total (% of Polygon Area)", ...dataLine.map((d) => (100 * d.total) / regionInfo.area.value)]);
        }

        if (yAxisMode === "polygon_area") {
            dataPie.forEach((d) => (sum += +d.value));
            dataPie.forEach((d) => {
                landUse.push([d.name, parseFloat((d.value / sum).toFixed(4)) * 100 + "%"]);
            });

            lossGain.push(["Year", ...dataBar.map((d) => d.name)]);
            lossGain.push(["Gain (% of total Area)", ...dataBar.map((d) => (100 * d.Gain) / getTotalArea(polygons))]);
            lossGain.push(["Loss (% of total Area)", ...dataBar.map((d) => (100 * d.Loss) / getTotalArea(polygons))]);
            lossGain.push(["Net (% of total Area)", ...dataBar.map((d) => (100 * d.Net) / getTotalArea(polygons))]);

            canopyCover.push(["Year", ...dataLine.map((d) => d.name)]);
            canopyCover.push(["Existing (% of Polygon Area)", ...dataLine.map((d) => (100 * d.existing) / getTotalArea(polygons))]);
            canopyCover.push(["Total (% of Polygon Area)", ...dataLine.map((d) => (100 * d.total) / getTotalArea(polygons))]);
        }

        let landUseBlob = getCsvBlob(landUse);
        let lossGainBlob = getCsvBlob(lossGain);
        let canopyCoverBlob = getCsvBlob(canopyCover);
        let lossGainV2Blob = getCsvBlob(lossGainV2);
        let scatterBlob = getCsvBlob(scatter);
        let plantingsByLanduseBlob = getCsvBlob(plantingsByLanduse);
        let treeHeightsBlob = getCsvBlob(treeHeights);
        let waterfallBlob = getCsvBlob(waterfall);

        const zip = jszip();
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_LandUse.csv`, landUseBlob);
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_CanopyCover.csv`, canopyCoverBlob);
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_LossGain.csv`, lossGainBlob);
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_LossGainV2.csv`, lossGainV2Blob);
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_CanopyCoverScatter.csv`, scatterBlob);
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_PlantingsByLanduse.csv`, plantingsByLanduseBlob);
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_TreeHeights.csv`, treeHeightsBlob);
        zip.file(`${region}_${isCompare ? "Agg" : chartsForPDF[0].title}_Waterfall.csv`, waterfallBlob);

        zip.generateAsync({
            type: "base64",
            compression: "DEFLATE",
            compressionOptions: {
                level: 4,
            },
        }).then(async (content) => {
            var link = document.createElement("a");
            link.href = "data:application/zip;base64," + content;
            link.download = "leaf_report.zip";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            MixpanelService.track("generate_csv", {
                distinct_id: user.id,
                login: user.login,
                role: user.role === 2 ? "admin" : "user",
                region,
            });
        });
    };

    const handleCSV = () => {
        MixpanelService.track("generate_csv", {
            distinct_id: user.id,
            login: user.login,
            role: user.role === 2 ? "admin" : "user",
            region,
        });
    };

    const handlePDF = () => {
        MixpanelService.track("generate_pdf", {
            distinct_id: user.id,
            login: user.login,
            role: user.role === 2 ? "admin" : "user",
            region,
        });
    };

    return (
        <>
            {(Boolean(treesCharts) || Boolean(treeInfo)) && (
                <Button onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.hide)} variant="outlined">
                    <ChevronLeftIcon style={{ transform: "scale(1.5)" }} />
                </Button>
            )}

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                // style={{width: "100%"}}
            >
                <div className={classes.drawerHeader}>
                    <IconButton className={classes.drawerIcon} onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>

                    <div className={classes.drawerContent}>
                        {!treeInfo && (
                            <AppBar position="static" color="default" classes={{ root: classes.appBar }}>
                                <Tabs
                                    value={tab}
                                    onChange={(e, v) => setTab(v)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    {isCompare ? <Tab label="Compare" {...a11yProps(1)} /> : <Tab label="Aggregate" {...a11yProps(0)} />}
                                </Tabs>
                            </AppBar>
                        )}

                        <div ref={componentRef}>
                            {treeInfo ? (
                                <IndividualGraph {...treeInfo} />
                            ) : (
                                <>
                                    {isCompare === false && (
                                        <TabPanel value={tab} index={0}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Select
                                                    value={yAxisMode}
                                                    onChange={(e) => {
                                                        setYAxisMode(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value={"polygon_area"}>% of Polygon Area</MenuItem>
                                                    <MenuItem value={"sqm"}>Square Meters</MenuItem>
                                                    <MenuItem value={"total_area"}>% of Total Region Area</MenuItem>
                                                </Select>
                                            </div>

                                            {treesCharts && checkIfAllGraps() === true ? (
                                                <Graph
                                                    dataChart={dataLine}
                                                    dataPie={dataPie}
                                                    dataBar={dataBar}
                                                    dataScatter={dataScatter}
                                                    dataLossGains={dataLossGains}
                                                    dataTotalArea={dataTotalArea}
                                                    dataVegetationRemovals={dataVegetationRemovals}
                                                    dataTreeHeights={dataTreeHeights}
                                                    dataTreeHeightsAboveGround={dataTreeHeightsAboveGround}
                                                    dataWaterfall={dataWaterfall}
                                                    status={status}
                                                    lastYear={lastYear}
                                                    currentCanopy={currentCanopy}
                                                    startingCanopy={startingCanopy}
                                                    contribution={contribution}
                                                    mode={yAxisMode}
                                                    polygonArea={polygons && getTotalArea(polygons)}
                                                    regionArea={regionInfo.area.value}
                                                    polygon={polygons}
                                                    summary={summary}
                                                    changeRatio={changeRatio}
                                                    firstYear={firstYear}
                                                />
                                            ) : (
                                                <CircularProgress style={{ marginTop: "30px" }} />
                                            )}
                                        </TabPanel>
                                    )}

                                    {isCompare && (
                                        <TabPanel value={tab} index={0}>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Select
                                                    value={yAxisMode}
                                                    onChange={(e) => {
                                                        setYAxisMode(e.target.value);
                                                    }}
                                                >
                                                    <MenuItem value={"polygon_area"}>% of Polygon Area</MenuItem>
                                                    <MenuItem value={"sqm"}>Square Meters</MenuItem>
                                                    <MenuItem value={"total_area"}>% of Total Region Area</MenuItem>
                                                </Select>
                                            </div>
                                            {compareGraph && checkIfAllCompareGraphs() === true ? (
                                                <CompareGraph
                                                    cumalative_loss_gain={compareGraph.cumalative_loss_gain}
                                                    existing_veg={compareGraph.existing_veg}
                                                    total_veg={compareGraph.total_veg}
                                                    name={"year"}
                                                    lines={keys}
                                                    polygonArea={polygons && getTotalArea(polygons)}
                                                    mode={yAxisMode}
                                                    regionArea={regionInfo.area.value}
                                                />
                                            ) : (
                                                <CircularProgress style={{ marginTop: "30px" }} />
                                            )}
                                        </TabPanel>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={classes.drawerPrintPdf}>
                        {!treeInfo ? (
                            <>
                                <ReactToPrint
                                    trigger={() => (
                                        <button id="print_butt" className={classes.analyseButton} color="secondary" variant="contained">
                                            Print to PDF
                                        </button>
                                    )}
                                    content={() => {
                                        handlePDF();
                                        return PDFRef.current;
                                    }}
                                />

                                {tab === 0 && isCompare === false && (
                                    <Button onClick={handleGenerateCSV} style={{ marginLeft: 20 }} color="primary" variant="contained">
                                        Export CSV
                                    </Button>
                                )}

                                {tab === 0 && isCompare === false && (
                                    <ReactToPrint
                                        trigger={() => (
                                            <button
                                                id="print_butt_ai"
                                                disabled={summary ? false : true}
                                                className={classes.analyseButton}
                                                color="secondary"
                                                variant="contained"
                                                style={{ marginLeft: 20 }}
                                            >
                                                Print AI summary
                                            </button>
                                        )}
                                        content={() => {
                                            // handlePDF();
                                            return AiSummaryPDFRef.current;
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <ReactToPrint
                                    trigger={() => (
                                        <button id="print_butt_ind" className={classes.analyseButton} color="secondary" variant="contained">
                                            Print to PDF
                                        </button>
                                    )}
                                    content={() => {
                                        handlePDF();
                                        return PDFRef.current;
                                    }}
                                />
                                <CSVLink data={csvData} filename={`${region}_${treeInfo.veg_id}.csv`}>
                                    <Button onClick={handleCSV} style={{ marginLeft: 20 }} color="primary" variant="contained">
                                        Export CSV
                                    </Button>
                                </CSVLink>
                            </>
                        )}
                        {!treeInfo ? (
                            chartsForPDF?.length > 1 && checkIfAllCompareGraphs() === true && isCompare && compareGraph ? (
                                <PDFCompareComponent
                                    ref={PDFRef}
                                    years={yearsDiap}
                                    compareGraph={compareGraph}
                                    keys={keys}
                                    agregateGraph={agregateGraph}
                                    region={region}
                                    filters={filters}
                                    parseRawCharts={parseRawCharts}
                                    chartsForPDF={chartsForPDF}
                                    mode={yAxisMode}
                                    polygonArea={polygons && getTotalArea(polygons)}
                                    regionArea={regionInfo && regionInfo.area.value}
                                />
                            ) : (
                                <>
                                    {checkIfAllGraps() === true && isCompare === false && (
                                        <PDFComponent
                                            agregateGraph={agregateGraph}
                                            years={yearsDiap}
                                            ref={PDFRef}
                                            region={region}
                                            filters={filters}
                                            chartsForPDF={chartsForPDF}
                                            mode={yAxisMode}
                                            polygonArea={polygons && getTotalArea(polygons)}
                                            regionArea={regionInfo && regionInfo.area.value}
                                        />
                                    )}

                                    {chartsForPDF && summary && isCompare === false && (
                                        <PDFAiSummaryComponent
                                            ref={AiSummaryPDFRef}
                                            summary={summary}
                                            polygonArea={polygons && getTotalArea(polygons)}
                                            regionArea={regionInfo && regionInfo.area.value}
                                            region={region}
                                            years={yearsDiap}
                                            filters={filters}
                                            chartsForPDF={chartsForPDF}
                                        />
                                    )}
                                </>
                            )
                        ) : (
                            treeInfo && (
                                <PDFIndividual treeInfo={treeInfo} region={region} filters={filters} chartsForPDF={chartsForPDF} ref={PDFRef} />
                            )
                        )}
                    </div>
                </div>
            </Drawer>
        </>
    );
});

export default RightSidebar;
