import React from "react";
import { LineChart, AreaChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ReferenceLine } from "recharts";
import InfoTooltip from "../LeftSidebar/InfoTooltip";

const legendFormatter = (value, payload) => {
    return (
        <span style={{ position: "relative" }}>
            {value}
            <InfoTooltip text={payload.help} style={{ position: "absolute", right: "-20px", top: 0 }} />
        </span>
    );
};

export default function ChartLine(props) {
    const {
        data,
        area = false,
        name = "name",
        lines,
        withHelp = false,
        handleSetActive = null,
        mode,
        preview = false,
        polygonArea,
        regionArea,
        individual = false
    } = props;

    if (!data) {
        return null;
    }

    let lineData = JSON.parse(JSON.stringify(data));

    lineData.forEach((el) => {
        Object.keys(el).forEach((key) => {
            if (key === name) {
                return;
            }
            if (mode === "total_area") {
                el[key] = Number(el[key].toFixed(6));
                return;
            }
            el[key] = Math.ceil(el[key]);
        });
    });

    if (mode === "polygon_area") {
        lineData = lineData.map((el) => {
            return {
                ...el,
                existing: (el.existing / polygonArea) * 100,
                total: (el.total / polygonArea) * 100,
            };
        });
    }

    if (mode === "total_area") {
        lineData = lineData.map((el) => {
            return {
                ...el,
                existing: (el.existing / regionArea) * 100,
                total: (el.total / regionArea) * 100,
            };
        });
    }

    const Chart = area ? AreaChart : LineChart;
    const ChartElem = area ? Area : Line;

    return (
        <Chart
            // width={460}
            // height={230}
            width={(preview === true && 200) || 600}
            height={(preview === true && 150) || 500}
            data={lineData}
            margin={{
                top: 20,
                right: 10,
                left: 0,
                bottom: 5,
            }}
            onMouseMove={(e) => handleSetActive && handleSetActive(e.activeLabel)}
            onMouseLeave={() => handleSetActive && handleSetActive(null)}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={{ fontSize: 11 }} minTickGap={0} dataKey={name} />
            <YAxis tick={{ fontSize: 11 }}>
                <Label
                    content={() => (
                        <>
                            <text
                                rotate={0}
                                x={-95}
                                y={10}
                                textAnchor="middle"
                                style={{ fill: "#3B3B3B", position: "absolute", fontWeight: 600, fontSize: 14, transform: "rotate(-90deg)" }}
                            >
                                {(mode === "sqm" || individual === true) ? "sqm" : "%"}
                            </text>
                        </>
                    )}
                />
            </YAxis>
            <ReferenceLine y={0} stroke="#A9A9A9" />
            <Tooltip />
            {lines.length &&
                lines.map((l) => (
                    <ChartElem
                        fillOpacity={0.6}
                        id={l.key}
                        key={l.key}
                        isAnimationActive={false}
                        strokeWidth={1.8}
                        type="monotone"
                        dataKey={l.key}
                        stroke={l.stroke}
                        fill={l.stroke}
                    />
                ))}
            <Legend
                wrapperStyle={{ fontSize: "15px" }}
                formatter={withHelp && legendFormatter}
                payload={lines.map((l) => ({ id: l.key, value: l.label || l.key, type: "line", color: l.stroke, help: l.help || "info" }))}
            />
        </Chart>
    );
}
