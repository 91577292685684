import React from "react";
import { Layer, Source } from "react-map-gl";
import { API_BASE_URL } from "../../config";

export const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "polygons",
    paint: {
        "circle-color": "#51bbd6",
        "circle-radius": 750,
    },
};

export const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "polygons",
    layout: {
        "text-field": "HELLO",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
    },
};

const SourcePolygons = ({ id, dataUrl, styles, hide = false }) => {
    if (hide) {
        return null;
    }
    return (
        <Source id={id} type="geojson" data={`${API_BASE_URL}/region/${dataUrl}`} buffer={0}>
            {!hide && <Layer {...styles} />}
        </Source>
    );
};

export default SourcePolygons;
