import axios from "axios";
import { API_BASE_URL } from "../../config";

const getToken = () => {
    let tk = window.localStorage.getItem("auth_token");
    if (tk === undefined || tk === "") {
        return;
    }
    return tk;
};

axios.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token !== undefined) {
            config.headers["Authorization"] = "bearer " + token;
        }
        config.baseURL = API_BASE_URL;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const treesAPI = {
    getTrees(data) {
        return axios.post(`/polygon_info`, { region_id: data.region_id, filters: data.filters, data: data.data });
    },
    getAiSummary(data) {
        return axios.post(`/ai_summary`, {
            region_id: data.region_id,
            filters: data.filters,
            data: data.data,
            question: data.question,
            graph_type: data.graph_type,
        });
    },
    getChartTrees(queryStr) {
        return axios.get(`/charts?dots=${queryStr}`);
    },
    checkPolygonsVersion(title) {
        return axios.get(`/region?title=${title}`);
    },
    getPolygons(id, handleDownloadProgress) {
        return axios.get(`/region/${id}`, { onDownloadProgress: handleDownloadProgress });
    },
    getLosses(id, handleDownloadProgress) {
        return axios.get(`/region/${id}?losses=true`, { onDownloadProgress: handleDownloadProgress });
    },
    getGains(id, handleDownloadProgress) {
        return axios.get(`/region/${id}?gains=true`, { onDownloadProgress: handleDownloadProgress });
    },
    getTreeById(region_id, id) {
        return axios.get(`/region/${region_id}/veg_info/${id}`);
        //return axios.get(`/tree/${id}`);
    },
    compareGraph(data) {
        return axios.post(`/graph_compare`, { region_id: data.region_id, filters: data.filters, data: data.data });
        //return axios.post(`/graph_compare`, formData)
    },
    uploadShpAndGetGeojson(formData) {
        return axios({
            url: `/get-shp-data`,
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });
    },
};

export const usersAPI = {
    login(login, password) {
        return axios.post("/auth/login", { login, password });
    },
    getUser() {
        return axios.get("/auth/me");
    },
    getUsersApi(page) {
        return axios.get("/users?page=" + page);
    },
    register(login, password) {
        return axios.post("/auth/register", { login, password });
    },
    deleteUserApi(id) {
        return axios.delete("/user/" + id);
    },
    updateUser(userId, payload) {
        return axios.put("/user/" + userId, payload);
    },
    updateUserPassword(userId, password) {
        return axios.put(`/user/${userId}/password`, { password });
    },
    addRegionToUser(userId, regionId) {
        return axios.put("/user/" + userId, { action: "add", region: regionId });
    },
    removeRegionFromUser(userId, regionId) {
        return axios.put("/user/" + userId, { action: "remove", region: regionId });
    },
};

export const sessionAPI = {
    getAll() {
        return axios.get("/session");
    },
    getMySessions(regionId) {
        return axios.get(`/session/me/region/${regionId}`);
    },
    create(userId, regionId, description, indexDBHash, polygons, editor) {
        return axios.post("/session", { userId, regionId, description, indexDBHash, polygons, editor });
    },
    delete(id) {
        return axios.delete(`/session/${id}`);
    },
    update(id, description) {
        return axios.put(`/session/${id}`, { description });
    },
};

export const RegionAPI = {
    getRegion(title) {
        return axios.get(`/region?title=${title}`);
    },
    getAll() {
        return axios.get("/regions");
    },
    getFiles() {
        return axios.get("/getFiles");
    },
    fillDatabase(fileName, filePath, regionId, regionTitle) {
        return axios.post("/fillDatabase", {
            fileName: fileName,
            filePath: filePath,
            regionId: regionId,
            regionTitle: regionTitle,
        });
    },
    addRegion(title) {
        return axios.post("/region", { title });
    },
    removeRegion(id) {
        return axios.delete("/region/" + id);
    },
    update(id, data) {
        return axios.put("/region/" + id, data);
    },
    upload(id, formData, handleUpload) {
        return axios({
            url: `/region/${id}/upload`,
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            onUploadProgress: handleUpload,
        });
    },
    uploadRegion(id, formData, handleUpload) {
        return axios({
            url: `/region/${id}/uploadRegion`,
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            onUploadProgress: handleUpload,
        });
    },
    uploadTest(formData) {
        return axios({
            url: `/test`,
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });
    },
    download(filename) {
        return axios({
            url: `/download?file=${filename}`,
            method: "GET",
            responseType: "blob",
        });
    },
};
