export const polygonsStyle = (a, b, c) => ({
    id: "polygons",
    type: "fill",
    paint: {
        "fill-color": "#68A673",
        "fill-opacity": 0.8,
    },
    filter: ["all", ...a, ["in", "landuse", ...b], ["!=", "veg_id", c]],
});

export const lossesStyle = (a, b, diapozon) => ({
    id: "losses",
    type: "fill",
    paint: {
        "fill-color": "#FC4236",
        "fill-opacity": 0.8,
    },
    filter: ["all", ...a, ["in", "landuse", ...b], [">=", "last_yr", diapozon[0]], ["<=", "last_yr", diapozon[diapozon.length - 1]]],
});

export const gainsStyle = (a, b, diapozon) => ({
    id: "gains",
    type: "fill",
    paint: {
        "fill-color": "#A2D8B9",
        "fill-opacity": 0.8,
    },
    filter: ["all", ...a, ["in", "landuse", ...b], [">=", "first_yr", diapozon[0]], ["<=", "first_yr", diapozon[diapozon.length - 1]]],
});

export const customDatasetStyle = (id, color, a, b, diapozon) => ({
    id,
    type: "fill",
    paint: {
        "fill-color": color,
        "fill-opacity": 0.8,
    },
    filter: ["all", ...a, ["in", "landuse", ...b], [">=", "year", diapozon[0]], ["<=", "year", diapozon[diapozon.length - 1]]],
});

export const treeStyle = {
    id: "tree_info",
    type: "fill",
    paint: {
        "fill-color": ["get", "color"],
        "fill-antialias": false,
        "fill-opacity": 1,
    },
};

export const treeHoverStyle = {
    id: "tree_info_hover",
    type: "fill",
    paint: {
        "fill-color": "#FFB43C",
        "fill-opacity": 1,
    },
};

export const shpStyle = {
    id: "shp",
    type: "line",
    paint: {
        "line-color": "#0080ef",
        "line-width": 2,
    },
};
