import React from "react";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, Label } from "recharts";

export default function ChartRadar(props) {
    const { data, mode, preview = false, polygonArea, regionArea } = props;

    if (!data) {
        return null;
    }

    let radarData = JSON.parse(JSON.stringify(data));

    radarData.forEach((el) => {
        Object.keys(el).forEach((key) => {
            if (key === "name") {
                return;
            }
            if (mode === "total_area") {
                el[key] = Number(el[key].toFixed(6));
                return;
            }
            el[key] = Math.ceil(el[key]);
        });
    });

    if (mode === "polygon_area") {
        radarData = radarData.map((el) => {
            return {
                ...el,
                Gain: (el.Gain / polygonArea) * 100,
                Loss: (el.Loss / polygonArea) * 100,
                Net: (el.Net / polygonArea) * 100,
            };
        });
    }

    if (mode === "total_area") {
        radarData = radarData.map((el) => {
            return {
                ...el,
                Gain: (el.Gain / regionArea) * 100,
                Loss: (el.Loss / regionArea) * 100,
                Net: (el.Net / regionArea) * 100,
            };
        });
    }

    return (
        <div>
            {data && (
                <ComposedChart
                    // width={460}
                    // height={230}
                    width={(preview === true && 220) || 600}
                    height={(preview === true && 150) || 500}
                    data={radarData}
                    stackOffset="sign"
                    margin={{
                        top: 20,
                        right: 10,
                        left: 0,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis tick={{ fontSize: 11 }} dataKey="name" />
                    <YAxis tick={{ fontSize: 11 }}>
                        <Label
                            content={() => (
                                <>
                                    <text
                                        rotate={0}
                                        x={-95}
                                        y={10}
                                        textAnchor="middle"
                                        style={{ fill: "#3B3B3B", position: "absolute", fontWeight: 600, fontSize: 14, transform: "rotate(-90deg)" }}
                                    >
                                        {mode === "sqm" ? "sqm" : "%"}
                                    </text>
                                </>
                            )}
                        />
                    </YAxis>
                    <Tooltip cursor={false} />
                    <Legend wrapperStyle={{ fontSize: "15px" }} />
                    <ReferenceLine y={0} stroke="#000" />
                    <Bar dataKey="Gain" fill="#68A673" stackId="stack" />
                    <Bar dataKey="Loss" fill="#A2D8B9" stackId="stack" />
                    <Line isAnimationActive={false} type="monotone" dataKey="Net" stroke="#AEAAAA" strokeWidth={2} />
                </ComposedChart>
            )}
        </div>
    );
}
