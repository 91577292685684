import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSession, deleteMySession, updateSession } from "../../Redux/SessionReducer";
import { EditingMode } from "react-map-gl-draw";

import { setManyShapes } from "../../Redux/LeftSidebarReducer";
import { setManyTreesLayers, setMode } from "../../Redux/TreesReducer";
import { Button, Collapse, TextField } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Delete from "../../assets/static/delete.svg";
import Edit from "../../assets/static/edit.svg";
import Location from "../../assets/static/location.svg";
import MixpanelService from "../../services/mixpanel-service";

const useStyles = makeStyles(() => ({
    popoverMy: {
        position: "absolute",
        top: "-20px",
        fontSize: "12px",
        color: "grey",
        textTransform: "initial",
        whiteSpace: "nowrap",
    },
    blockEdit: {
        width: "95%",
        display: "flex",
        justifyContent: "space-between",
    },
    inputEdit: {
        width: "300px",
    },
    dateTimeSession: {
        fontSize: "13px",
        color: "grey",
    },
    analyseButton: {
        background: "#FC4236",
        "&:hover": {
            background: "#FC4236",
        },
    },
}));

const DialogCreateSession = React.memo(({ open, regionId, setOpen, sessions, editor }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [editText, setEditText] = useState("");
    const [anchorHoverEl, setAnchorHoverEl] = useState(null);
    const [selectedSession, setSelectedSession] = useState(-1);
    const [modalDescription, setModalDescription] = useState("");
    const [editSession, setEditSession] = useState(-1);

    const user = useSelector((state) => state.user.user);
    const treesLayers = useSelector((state) => state.trees.tressLayers);
    const isFetching = useSelector((state) => state.trees.isFetching);

    const handleModalClose = () => {
        setOpen(false);
        setEditSession(-1);
    };

    const handleSetSelectedSession = (id) => {
        if (editSession !== -1 && id === editSession) {
            return;
        }
        setSelectedSession(id);
        if (id !== -1 && editSession !== -1) {
            setEditSession(-1);
        }
    };

    const getParsedDate = (ISOdate) => {
        let date = new Date(ISOdate),
            year = date.getFullYear(),
            month = date.getMonth() + 1,
            dt = date.getDate(),
            h = date.getHours(),
            m = date.getMinutes();

        if (dt < 10) {
            dt = "0" + dt;
        }
        if (month < 10) {
            month = "0" + month;
        }
        if (h < 10) {
            h = "0" + h;
        }
        if (m < 10) {
            m = "0" + m;
        }

        return `created ${dt}.${month}.${year} ${h}:${m}`;
    };

    const handleEditSession = (session) => {
        setEditSession(session.id);
        setEditText(session.description);
    };

    const handleCreateSession = async () => {
        let polygons = treesLayers.map((p) => p.polygons);

        let hash = user.id;
        let num = 0;
        if (treesLayers) {
            treesLayers.forEach((l) => {
                l.polygons.forEach((c) => {
                    num += c[0] + c[1];
                });
            });
        }

        let editorPolygons = [];
        if (editor.current) {
            editorPolygons = editor.current.getFeatures();
            if (editorPolygons) {
                editorPolygons.forEach((ed) => {
                    ed.properties.isSelected = false;
                    ed.properties.isActive = false;
                    ed.geometry.coordinates[0].forEach((pair) => (num += pair[0] + pair[1]));
                });
            }
        }

        hash += num;

        await dispatch(addSession(user.id, regionId, modalDescription, hash, polygons, editorPolygons));
        handleModalClose();
    };

    const handleRemoveSession = (session) => {
        dispatch(deleteMySession(session.id));
    };

    const handleEditOkSession = async (id) => {
        dispatch(await updateSession(id, editText));
        setEditSession(-1);
    };

    const handleLoadSession = async (session) => {
        let polygons = session.polygons;
        if (!Array.isArray(polygons)) {
            polygons = JSON.parse(polygons);
        }
        polygons = polygons.map((el) => ({
            polygons: el,
        }));

        dispatch(setManyTreesLayers(polygons));
        // let hash = user.id;
        let num = 0;
        polygons.forEach((l) => {
            l.polygons.forEach((c) => {
                num += c[0] + c[1];
            });
        });

        let editorPolygons = session.editor;
        if (!Array.isArray(editorPolygons)) {
            editorPolygons = JSON.parse(editorPolygons);
        }

        if (editorPolygons) {
            editorPolygons.forEach((ed) => {
                ed.geometry.coordinates[0].forEach((pair) => (num += pair[0] + pair[1]));
                ed.properties.isVisible = true;
                ed.sessionName = session.description;
            });
        }
        // hash += num;
        if (editorPolygons.length) {
            dispatch(setManyShapes(editorPolygons));
            await dispatch(setMode(new EditingMode()));
            let features = editor.current.getFeatures();
            for (let i = 0; i < features.length; i++) {
                await editor.current.deleteFeatures(0);
            }
            editor.current.addFeatures(editorPolygons);
        }

        MixpanelService.track("user_loaded_session", {
            login: user.login,
            user_id: user.id,
            session_name: session.description,
        });

        handleModalClose();
    };

    const handleKeyPress = (e) => e.code === "Enter" && handleCreateSession();

    return (
        <Dialog fullWidth={true} maxWidth={open === "load" ? "sm" : "xs"} transitionDuration={300} open={Boolean(open)} onClose={handleModalClose}>
            {open === "save" ? (
                <>
                    <DialogTitle id="form-dialog-title">Save Session</DialogTitle>

                    <DialogContent>
                        <DialogContentText>Write a description of the session.</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Description"
                            type="decription"
                            fullWidth
                            onKeyPress={handleKeyPress}
                            onChange={(e) => setModalDescription(e.target.value)}
                        />
                    </DialogContent>

                    <DialogActions style={{ margin: "5px 15px" }}>
                        <Button onClick={handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button disabled={!modalDescription.length ? true : false} onClick={handleCreateSession} color="primary" variant="contained">
                            Save Session
                        </Button>
                    </DialogActions>
                </>
            ) : open === "load" ? (
                <>
                    <DialogTitle id="form-dialog-title">Load Your Session</DialogTitle>
                    {!sessions?.length && <em style={{ marginLeft: 33 }}>-There are no sessions here yet-</em>}
                    <List>
                        {sessions.map((s) => (
                            <div key={s.id}>
                                <ListItem
                                    selected={selectedSession === s.id}
                                    button
                                    onClick={() => (selectedSession === s.id ? handleSetSelectedSession(-1) : handleSetSelectedSession(s.id))}
                                >
                                    <ListItemText>
                                        {editSession === s.id ? (
                                            <div className={classes.blockEdit}>
                                                <TextField
                                                    className={classes.inputEdit}
                                                    value={editText}
                                                    onChange={(e) => setEditText(e.target.value)}
                                                />

                                                <Button onClick={() => handleEditOkSession(s.id)} variant="outlined" color="primary" size="small">
                                                    {/* <Icon>save</Icon> */}
                                                    <Delete />
                                                </Button>
                                            </div>
                                        ) : (
                                            <div style={{ maxWidth: "92%" }}>{s.description}</div>
                                        )}
                                    </ListItemText>
                                    <span className={classes.dateTimeSession}>{getParsedDate(s.createdAt)}</span>

                                    {s.id === selectedSession ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                {editSession !== s.id && (
                                    <Collapse in={s.id === selectedSession} style={{ background: "rgba(0, 0, 0, 0.08)" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 20px" }}>
                                            <div>
                                                <Button
                                                    onMouseEnter={() => setAnchorHoverEl("Remove")}
                                                    onMouseLeave={() => setAnchorHoverEl(null)}
                                                    disabled={isFetching}
                                                    onClick={() => handleRemoveSession(s)}
                                                    style={{ marginRight: "20px" }}
                                                    size="small"
                                                    variant="contained"
                                                >
                                                    {anchorHoverEl === "Remove" && <div className={classes.popoverMy}>Remove</div>}
                                                    {/* <Icon style={{ color: "#FC4236" }}>delete</Icon> */}
                                                    <img src={Delete} width="24" color="#FC4236" alt="delete" />
                                                </Button>

                                                <Button
                                                    onClick={() => handleEditSession(s)}
                                                    onMouseEnter={() => setAnchorHoverEl("Edit")}
                                                    onMouseLeave={() => setAnchorHoverEl(null)}
                                                    disabled={isFetching}
                                                    style={{ marginRight: "20px" }}
                                                    size="small"
                                                    variant="contained"
                                                >
                                                    {anchorHoverEl === "Edit" && <div className={classes.popoverMy}>Edit</div>}
                                                    {/* <Icon style={{ color: "#FC4236" }}>create</Icon> */}
                                                    <img src={Edit} width="24" alt="edit" />
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    onMouseEnter={() => setAnchorHoverEl("Download")}
                                                    onMouseLeave={() => setAnchorHoverEl(null)}
                                                    disabled={isFetching}
                                                    onClick={() => handleLoadSession(s)}
                                                    variant="contained"
                                                >
                                                    {anchorHoverEl === "Download" && <div className={classes.popoverMy}>Add to map</div>}
                                                    {isFetching ? (
                                                        <CircularProgress style={{ width: "25px", height: "25px" }} />
                                                    ) : (
                                                        <>
                                                            {/* <Icon style={{ color: "#FFFFFF", fontSize: 18, marginRight: 4 }}>room</Icon>Load Session */}
                                                            <img src={Location} width="24" alt="location" />
                                                            Load Session
                                                        </>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                    </List>
                </>
            ) : open === "polygon" ? (
                <>
                    <DialogTitle>POLYGON Builder</DialogTitle>

                    <DialogContent>
                        <div style={{ marginTop: "-10px" }}>Click to select multiple points. Double click to close shape</div>

                        <div style={{ display: "flex", justifyContent: "center", padding: "20px 0 10px" }}>
                            <Button onClick={handleModalClose} variant="contained">
                                Dismiss
                            </Button>
                        </div>
                    </DialogContent>
                </>
            ) : (
                open === "circle" && (
                    <>
                        <DialogTitle>CIRCLE Builder</DialogTitle>

                        <DialogContent>
                            <div style={{ marginTop: "-10px" }}>Click to select the center of the circle. Select radius with mouse move</div>
                            <div style={{ display: "flex", justifyContent: "center", padding: "20px 0 10px" }}>
                                <Button onClick={handleModalClose} variant="contained">
                                    Dismiss
                                </Button>
                            </div>
                        </DialogContent>
                    </>
                )
            )}
        </Dialog>
    );
});

export default DialogCreateSession;
